import type { IconSvgProps } from "../types/types";
import React from "react";

export const VIcon: React.FC<IconSvgProps> = ({ size = 32, width, height, ...props }) => (
    <svg
        id="v.watch"
        data-name="v.watch"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 519.27 483.89"
        width={size || width}
        height={size || height}
        {...props}
    >
        <defs>
            <style>
                {`
          .cls-1, .cls-2 {
            fill: #dd3465;
            stroke-width: 0px;
          }
          .cls-2 {
            fill-rule: evenodd;
          }
        `}
            </style>
        </defs>
        <path
            className="cls-2"
            d="M233.04,1.39c-33.4,3.7-71,16.6-100.4,34.5-21.3,13-44.8,33.7-61.2,54-27.3,34-44.4,73.5-51.5,119-2.4,16-2.4,50,0,66,8.2,52.6,29.6,96.8,65.1,133.7,20.4,21.3,41.4,36.8,67.1,49.4,17.1,8.4,29.4,12.9,49,17.9,20.7,5.3,34.9,7,59,7s40.2-2.1,62-8.1c96-26.3,164.1-104.5,177-203.2,1.7-13.7,2-42.3.4-56.5-3.5-32.6-15.9-69.4-33-98.1-17-28.4-45.3-58-72.9-76.3C346.34,9.39,288.84-4.71,233.04,1.39ZM219.64,227.09c21.7,60.4,39.7,109.8,40,109.8s18.1-49.1,39.5-109c21.5-60,39.3-109.5,39.6-110.1s13.7-.8,35.6-.7l35,.3-55.6,144.2-55.5,144.3h-77.1l-55.4-143.3c-30.4-78.7-55.6-143.8-55.8-144.5-.4-1,6.7-1.2,34.8-1l35.4.3,39.5,109.7Z"
        />
        <rect className="cls-1" x="18.14" y=".22" width="482.45" height="110.7" />
        <rect
            className="cls-1"
            x="-186.87"
            y="187.09"
            width="483.67"
            height="109.93"
            transform="translate(297.02 187.09) rotate(90)"
        />
        <rect
            className="cls-1"
            x="222.47"
            y="187.09"
            width="483.67"
            height="109.93"
            transform="translate(706.36 -222.25) rotate(90)"
        />
        <rect className="cls-1" x="18.14" y="412.54" width="482.45" height="71.35" />
    </svg>
);