import React from "react";
import {Image} from "@nextui-org/react";
import {Icon} from "@iconify/react";
import {Video} from "./VideoList";

interface VideoListItemProps extends Video {
    isLoading?: boolean;
    onVideoClick: (videoId: string) => void;
}

const VideoListItem: React.FC<VideoListItemProps> = ({
                                                         videoid,
                                                         title,
                                                         note,
                                                         price,
                                                         duration,
                                                         isLoading,
                                                         onVideoClick,
                                                     }) => {
    const formatDuration = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className="w-full overflow-hidden">
            {isLoading ? (
                <div className="h-[200px] bg-default-300 animate-pulse rounded-large" />
            ) : (
                <div className="flex flex-col h-full">
                    <div
                        className="relative group rounded-large overflow-hidden cursor-pointer"
                        onClick={() => onVideoClick(videoid)}
                    >
                        <Image
                            isZoomed
                            width="100%"
                            alt={title}
                            className="w-full object-cover aspect-square z-0 transition-transform duration-300 group-hover:scale-105 select-none pointer-events-none"
                            src={`https://file.v.watch/cover/${videoid}.jpg`}
                            draggable={false}
                            style={{ WebkitTouchCallout: 'none' }}
                        />
                        <div className="absolute bottom-2 right-2 bg-background/60 backdrop-blur-md backdrop-saturate-150 dark:bg-default-100/50 px-2 py-1 rounded-lg text-tiny font-medium z-10 pointer-events-none select-none">
                            {formatDuration(duration)}
                        </div>
                    </div>
                    <div className="flex flex-col p-2 flex-grow">
                        <div className="flex justify-between items-center w-full mb-1">
                            <h4 className="font-bold text-large truncate">{title}</h4>
                            {price > 0 && (
                                <div className="flex items-center ml-2 flex-shrink-0">
                                    <Icon icon="cryptocurrency-color:matic" className="text-yellow-400 mr-1" width={16}/>
                                    <span className="text-small font-medium">{price}</span>
                                </div>
                            )}
                        </div>
                        <p className="text-small text-default-500 line-clamp-2">{note}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoListItem;