import React, { useState, useRef } from 'react';
import ReactCrop, { Crop, PercentCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useTranslation } from 'react-i18next';

interface ImageCropperProps {
    coverImage: string;
    MIN_WIDTH: number;
    MIN_HEIGHT: number;
    onSaveCrop: (croppedImage: string) => void;
    onReupload: () => void;
    initialAspectRatio: number; // 新添加的属性
}

const ImageCropper: React.FC<ImageCropperProps> = ({
    coverImage,
    MIN_WIDTH,
    MIN_HEIGHT,
    onSaveCrop,
    onReupload,
    initialAspectRatio
}) => {
    const { t } = useTranslation();
    const [crop, setCrop] = useState<Crop>({
        unit: 'px',
        width: MIN_WIDTH,
        height: MIN_HEIGHT,
        x: 0,
        y: 0
    });
    const [completedCrop, setCompletedCrop] = useState<PercentCrop | null>(null);
    const [isCropValid, setIsCropValid] = useState(false);
    const imgRef = useRef<HTMLImageElement | null>(null);

    const validateCrop = (crop: Crop, img: HTMLImageElement) => {
        console.log('img size', img.height, img.width)
        return crop.width >= MIN_WIDTH && crop.height >= MIN_HEIGHT;
    };

    const onCropChange = (c: Crop, percentCrop: PercentCrop) => {
        setCrop(c);
        setCompletedCrop(percentCrop);
    };

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const { width, height } = e.currentTarget;
        const aspect = initialAspectRatio;

        let cropWidth = Math.max(MIN_WIDTH, width * 0.8);
        let cropHeight = cropWidth / aspect;

        // 确保裁剪框不超过图片边界
        if (cropHeight > height) {
            cropHeight = height;
            cropWidth = cropHeight * aspect;
        }

        const newCrop: Crop = {
            unit: 'px',
            width: cropWidth,
            height: cropHeight,
            x: (width - cropWidth) / 2,
            y: (height - cropHeight) / 2
        };

        setCrop(newCrop);
        const percentCrop: PercentCrop = {
            unit: '%',
            width: (cropWidth / width) * 100,
            height: (cropHeight / height) * 100,
            x: ((width - cropWidth) / 2 / width) * 100,
            y: ((height - cropHeight) / 2 / height) * 100
        };
        setCompletedCrop(percentCrop);
        setIsCropValid(validateCrop(newCrop, e.currentTarget));
    };

    const getCroppedImg = (image: HTMLImageElement, crop: Crop): string | null => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = Math.max(MIN_WIDTH, crop.width * scaleX);
        canvas.height = Math.max(MIN_HEIGHT, crop.height * scaleY);
        const ctx = canvas.getContext('2d');

        if (!ctx) return null;

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            canvas.width,
            canvas.height
        );

        return canvas.toDataURL('image/jpeg');
    };

    const handleSaveCrop = () => {
        if (imgRef.current && completedCrop && isCropValid) {
            const pixelCrop: Crop = {
                unit: 'px',
                width: (completedCrop.width / 100) * imgRef.current.width,
                height: (completedCrop.height / 100) * imgRef.current.height,
                x: (completedCrop.x / 100) * imgRef.current.width,
                y: (completedCrop.y / 100) * imgRef.current.height
            };
            const croppedImage = getCroppedImg(imgRef.current, pixelCrop);
            if (croppedImage) {
                onSaveCrop(croppedImage);
            }
        }
    };

    return (
        <div>
            <ReactCrop
                crop={crop}
                onChange={onCropChange}
                onComplete={onCropChange}
                aspect={initialAspectRatio}
                minWidth={MIN_WIDTH}
                minHeight={MIN_HEIGHT}
            >
                <img ref={imgRef} src={coverImage} alt="Video cover" onLoad={onImageLoad} />
            </ReactCrop>
            <p className="mt-2 text-sm text-gray-600">
                {t('imageCropper.minimumSize', {
                    width: MIN_WIDTH,
                    height: MIN_HEIGHT,
                    ratio: '191:100'
                })}
            </p>
            <div className="mt-4 flex space-x-4">
                <button
                    onClick={onReupload}
                    className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-800"
                >
                    {t('imageCropper.reupload')}
                </button>
                <button
                    onClick={handleSaveCrop}
                    disabled={!isCropValid}
                    className={`px-4 py-2 text-white rounded ${isCropValid ? 'bg-green-500 hover:bg-green-600' : 'bg-gray-400 cursor-not-allowed'}`}
                >
                    {t('imageCropper.saveCrop')}
                </button>
            </div>
        </div>
    );
};

export default ImageCropper;