import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';

export interface SplitterOption {
    value: string;
    label: string;
}

interface SplitterProps {
    splitterOptions: SplitterOption[];
    setSplitterOptions: React.Dispatch<React.SetStateAction<SplitterOption[]>>;
}

const Splitter: React.FC<SplitterProps> = ({ splitterOptions, setSplitterOptions }) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState<string | null>(null);

    const [isMobile] = useState(() => {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    });

    const validateAddress = (address: string): boolean => {
        const web3 = new Web3();
        return web3.utils.isAddress(address);
    };

    const isDuplicateAddress = (address: string): boolean => {
        return splitterOptions.some(option => option.value.toLowerCase() === address.toLowerCase());
    };

    const handleAddressSubmit = (address: string) => {
        if (address.startsWith('0x') && validateAddress(address)) {
            if (isDuplicateAddress(address)) {
                setError(t('splitter.errors.duplicate'));
            } else {
                const newOption = { value: address, label: address };
                setSplitterOptions((prev) => [...prev, newOption]);
                setInputValue('');
                setError(null);
            }
        } else {
            setError(t('splitter.errors.invalid'));
        }
    };

    const handleInputChange = (newValue: string) => {
        setInputValue(newValue);
        setError(null);
    };

    const formatOptionLabel = ({ value }: SplitterOption) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {value}
        </div>
    );

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && inputValue) {
            handleAddressSubmit(inputValue);
        }
    };

    return (
        <div className="mb-4">
            <label className="block mb-2 font-bold">{t('splitter.label')}</label>
            {isMobile ? (
                <div className="flex gap-2">
                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => handleInputChange(e.target.value)}
                        onKeyDown={handleKeyDown}
                        className="flex-1 p-2 border rounded font-mono"
                        placeholder={t('splitter.placeholder.mobile')}
                    />
                </div>
            ) : (
                <CreatableSelect
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    components={{ DropdownIndicator: null }}
                    inputValue={inputValue}
                    value={splitterOptions}
                    onChange={(newValue) => setSplitterOptions(newValue as SplitterOption[])}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder={t('splitter.placeholder.desktop')}
                    formatOptionLabel={formatOptionLabel}
                    styles={{
                        control: (base) => ({
                            ...base,
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                        }),
                        multiValue: (base) => ({
                            ...base,
                            marginBottom: '0.5rem',
                            maxWidth: '100%',
                        }),
                        multiValueLabel: (base) => ({
                            ...base,
                            fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }),
                        input: (base) => ({
                            ...base,
                            fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
                        }),
                    }}
                />
            )}
            {splitterOptions.length > 0 && (
                <div className="mt-2">
                    {splitterOptions.map((option) => (
                        <div 
                            key={option.value}
                            className="flex justify-between items-center p-2 mb-2 bg-gray-100 rounded"
                        >
                            <span className="font-mono truncate">{option.value}</span>
                            <button
                                onClick={() => setSplitterOptions(prev => 
                                    prev.filter(item => item.value !== option.value)
                                )}
                                className="ml-2 text-red-500"
                                aria-label={t('splitter.removeAddress')}
                            >
                                ×
                            </button>
                        </div>
                    ))}
                </div>
            )}
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
    );
};

export default Splitter;