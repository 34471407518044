import React, {useState, useEffect, useRef} from "react";
import HorizontalSteps from "./HorizontalSteps";
import { api } from "../services/api";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { S3Client, PutObjectCommandInput } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import ImageCropper from './ImageCropper';
import {
    Input,
    Textarea,
    Switch,
    Slider,
    Modal,
    Button,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter, useDisclosure,
    Select,
    SelectItem
} from "@nextui-org/react";
import { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { v4 as uuidv4 } from 'uuid';
import Splitter, { SplitterOption } from "./Splitter";
import {FilePondFile} from "filepond";
import {usePrivy, useWallets} from "@privy-io/react-auth";
import {Icon} from "@iconify/react";
import { polygon } from 'wagmi/chains'
import {ethers} from "ethers";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const addrVSmartContract = '0x865f78Ed1641791870293327E2Ca35c6FD146e34';
const addrBountySmartContract = '0x9d386a56CE518fE64fCEf3f077507892C6a17822'
import { ApiResponse, RoomData, Room } from '../services/api';
import { contractVABI } from "../services/contractVABI";
import { contractBountyABI } from "../services/contractBountyABI";

// todo 0.8

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

interface TagOption {
    value: string;
    label: string;
}

const generateUUIDWithoutHyphens = (): string => {
    return uuidv4().replace(/-/g, '');
};

export default function Component() {
    const { t } = useTranslation();
    const { ready, login, authenticated } = usePrivy();
    const { wallets } = useWallets();
    const [isCheckingAuth, setIsCheckingAuth] = useState(true);
    const [currentStep, setCurrentStep] = useState(0);
    const [files, setFiles] = useState<FilePondFile[]>([]);
    const [sessionCreation, setSessionCreation] = useState<string | ''>('');
    const [isUploadComplete, setIsUploadComplete] = useState(false);
    const [showUploadWarning, setShowUploadWarning] = useState(false);
    const [step1Completed, setStep1Completed] = useState(false);
    const [coverImage, setCoverImage] = useState<string | null>(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
    const [imageSizeError, setImageSizeError] = useState<string | null>(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [tags, setTags] = useState<TagOption[]>([]);
    const [visibility, setVisibility] = useState(true);
    const [price, setPrice] = useState<number>(0);
    const [isStep3Valid, setIsStep3Valid] = useState(false);
    const userLoginData = JSON.parse(localStorage.getItem('userLoginData') ?? '{}');
    const filePondRef = useRef<FilePond | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [splitterOptions, setSplitterOptions] = useState<SplitterOption[]>([]);
    const [videoId, setVideoId] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { isOpen: isSuccessModalOpen, onOpen: onSuccessModalOpen, onClose: onSuccessModalClose } = useDisclosure();
    const [rooms, setRooms] = useState<Room[]>([]);
    const [selectedRoom, setSelectedRoom] = useState<string | null>(null);
    const { nftid } = useParams<{ nftid?: string }>();
    const [challengeNftId, setChallengeNftId] = useState<string | null>(null);
    const [createChallenge, setCreateChallenge] = useState(false);
    const [challengeBonus, setChallengeBonus] = useState(1);
    const [challengeDescription, setChallengeDescription] = useState("");
    const [isChallengeDescriptionValid, setIsChallengeDescriptionValid] = useState(false);
    const [audioDuration, setAudioDuration] = useState<number>(0);

    const stepContents = [
        { title: t('creation.steps.prepareVideo'), content: t('creation.steps.prepareVideoDesc') },
        { title: t('creation.steps.chooseCover'), content: t('creation.steps.chooseCoverDesc') },
        { title: t('creation.steps.basicInfo'), content: t('creation.steps.basicInfoDesc') },
        { title: t('creation.steps.advancedSettings'), content: t('creation.steps.advancedSettingsDesc') },
        { title: t('creation.steps.review'), content: t('creation.steps.reviewDesc') }
    ];

    const MIN_WIDTH = 400;
    const MIN_HEIGHT = 200;

    const defaultTags: TagOption[] = [
        { value: 'music', label: t('creation.defaultTags.music') },
        { value: 'shortFile', label: t('creation.defaultTags.shortFile') },
        { value: 'vlog', label: t('creation.defaultTags.vlog') },
        { value: 'entertainment', label: t('creation.defaultTags.entertainment') },
        { value: 'technology', label: t('creation.defaultTags.technology') },
        { value: 'lifestyle', label: t('creation.defaultTags.lifestyle') }
    ];

    const handleViewVideo = () => {
        if (videoId) {
            window.location.href = `/video/${videoId}`;
        }
        setShowSuccessModal(false);
    };

    const validateImageSize = (file: File): Promise<boolean> => {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                if (img.width < MIN_WIDTH || img.height < MIN_HEIGHT) {
                    setImageSizeError(`Image dimensions must be at least ${MIN_WIDTH}x${MIN_HEIGHT} pixels. Uploaded image is ${img.width}x${img.height} pixels.`);
                    resolve(false);
                } else {
                    setImageSizeError(null);
                    resolve(true);
                }
            };
            img.src = URL.createObjectURL(file);
        });
    };

    const extractFirstFrame = async (file: File) => {
        if (file.type.startsWith('image/')) {
            const isValidSize = await validateImageSize(file);
            if (!isValidSize) {
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                setCoverImage(e.target?.result as string);
                setCroppedImageUrl(null);
            };
            reader.readAsDataURL(file);
        } else if (file.type.startsWith('video/')) {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
                video.currentTime = 0;
            };
            video.onseeked = () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                if (canvas.width < MIN_WIDTH || canvas.height < MIN_HEIGHT) {
                    setImageSizeError(`Video frame dimensions must be at least ${MIN_WIDTH}x${MIN_HEIGHT} pixels. Current frame is ${canvas.width}x${canvas.height} pixels.`);
                    return;
                }
                setImageSizeError(null);
                canvas.getContext('2d')?.drawImage(video, 0, 0);
                const dataUrl = canvas.toDataURL('image/jpeg');
                setCoverImage(dataUrl);
                setCroppedImageUrl(null);
            };
            video.src = URL.createObjectURL(file);
        } else if (file.type.startsWith('audio/')) {
            const audio = new Audio();
            audio.src = URL.createObjectURL(file);
            audio.onloadedmetadata = () => {
                setAudioDuration(Math.round(audio.duration));
            };
            
            const defaultAudioCover = '/images/audio.jpg';
            setCoverImage(defaultAudioCover);
            setCroppedImageUrl(null);
            setImageSizeError(null);
        }
    };

    const handleStepChange = (stepIndex: number) => {
        if (stepIndex <= currentStep && !(stepIndex === 0 && step1Completed)) {
            setCurrentStep(stepIndex);
        }
    };

    const handleNext = () => {
        if (currentStep === 0 && !isUploadComplete) {
            setShowUploadWarning(true);
            alert(t('creation.upload.warning'));
            return;
        }
        if (currentStep === 1 && !croppedImageUrl) {
            alert(t('creation.upload.coverWarning'));
            return;
        }
        if (currentStep < stepContents.length - 1) {
            if (currentStep === 0) {
                setStep1Completed(true);
            }
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0 && !(currentStep === 1 && step1Completed)) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleTagChange = (
        newValue: MultiValue<TagOption>
    ) => {
        setTags(newValue as TagOption[]);
    };

    const getSessionAndUploadToS3 = async (file: File, load: (response: string) => void, error: (errorText: string) => void, progress: (percentage: number, chunk: number, total: number) => void, abort: () => void) => {
        try {
            const session_creation = await generateUUIDWithoutHyphens();
            setSessionCreation(session_creation);
            const apiResponse = await api.getSessionForUploading(userLoginData.userid, session_creation);

            if (!apiResponse.data) {
                throw new Error('Invalid API response');
            }

            const s3Client = new S3Client({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: apiResponse.data.access_key_id,
                    secretAccessKey: apiResponse.data.secret_access_key,
                    sessionToken: apiResponse.data.session_token,
                },
            });

            const key = `convert_input/${videoId}`;

            const params = {
                Bucket: 'vevue-us',
                Key: key,
                Body: file,
            };

            const uploader = new Upload({
                client: s3Client,
                params: params,
            });

            uploader.on('httpUploadProgress', (progressEvent) => {
                if (typeof progressEvent.loaded === 'number' && typeof progressEvent.total === 'number') {
                    const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    progress(percentage, progressEvent.loaded, progressEvent.total);
                }
            });

            await uploader.done();
            load('Upload completed');
            setIsUploadComplete(true);
        } catch (err: unknown) {
            if (err instanceof Error) {
                if (err.name === 'AbortError') {
                    abort();
                } else {
                    error('Upload failed: ' + err.message);
                }
            } else {
                error('Upload failed: An unknown error occurred');
            }
            setIsUploadComplete(false);
        }
    };

    const handleReupload = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            if (file.type.startsWith('image/')) {
                const isValidSize = await validateImageSize(file);
                if (isValidSize) {
                    extractFirstFrame(file);
                }
            } else if (file.type.startsWith('video/') || file.type.startsWith('audio/')) {
                extractFirstFrame(file);
            }
        }
    };

    const uploadCoverImageToS3 = async (imageBlob: Blob, videoid: string) => {
        try {
            const apiResponse = await api.getSessionForUploading(userLoginData.userid, await generateUUIDWithoutHyphens());

            if (!apiResponse.data) {
                throw new Error('Invalid API response');
            }

            const s3Client = new S3Client({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: apiResponse.data.access_key_id,
                    secretAccessKey: apiResponse.data.secret_access_key,
                    sessionToken: apiResponse.data.session_token,
                },
            });

            const key = `cover/${videoid}.jpg`;

            const params: PutObjectCommandInput = {
                Bucket: 'vevue-us',
                Key: key,
                Body: imageBlob,
                ContentType: 'image/jpeg',
            };

            const uploader = new Upload({
                client: s3Client,
                params: params,
            });

            await uploader.done();
            console.log('Cover image uploaded successfully');
        } catch (err: unknown) {
            if (err instanceof Error) {
                console.error('Cover image upload failed:', err.message);
            } else {
                console.error('Cover image upload: An unknown error occurred');
            }
            throw err;
        }
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        setLoadingStatus(t('creation.loadingStatus.imageUploading'));

        try {
            if (!videoId) {
                throw new Error('Video ID not found');
            }

            if (!croppedImageUrl) {
                throw new Error('Cropped image not found');
            }

            if (!sessionCreation) {
                throw new Error('Session creation ID not found');
            }

            // 判断文件类型是否为音频
            const isAudio = files[0]?.file.type.startsWith('audio/') ? '1' : '0';

            // 如果是音频文件但没有获取到时长，抛出错误
            if (isAudio === '1' && !audioDuration) {
                throw new Error('Audio duration not available');
            }

            // Convert the cropped image URL to a Blob
            const response = await fetch(croppedImageUrl);
            const blob = await response.blob();

            // Upload the cover image
            await uploadCoverImageToS3(blob, videoId);

            setLoadingStatus(t('creation.loadingStatus.nftMinting'));

            // Get the Privy embedded wallet
            const embeddedWallet = wallets.find(wallet => wallet.walletClientType === 'privy');
            if (!embeddedWallet) {
                throw new Error('Embedded wallet not found');
            }

            // Get provider
            const provider = await embeddedWallet.getEthersProvider();

            // Check network
            await embeddedWallet.switchChain(polygon.id);
            const network = await provider.getNetwork();
            if (network.chainId !== polygon.id) {
                throw new Error(`Please switch to Polygon Mainnet, current is ${network.name} (${network.chainId})`);
            }

            // Create contract instance
            const contract = new ethers.Contract(addrVSmartContract, contractVABI, provider.getSigner());

            // Prepare transaction parameters
            const priceInWei = ethers.utils.parseUnits((price * 1e6).toFixed(0), 12);
            const bountyHolders = splitterOptions.length > 0 ? splitterOptions.map(item => item.value) : [embeddedWallet.address];

            // Estimate gas
            const gasEstimate = await contract.estimateGas.createNFT(videoId, bountyHolders, priceInWei);

            // Send transaction
            const tx = await contract.createNFT(videoId, bountyHolders, priceInWei, {
                gasLimit: gasEstimate.mul(120).div(100)
            });

            console.log('Transaction sent:', tx.hash);

            if (createChallenge) {
                setLoadingStatus(t('creation.loadingStatus.creatingChallenge'));
                
                // 获取嵌入式钱包
                const embeddedWallet = wallets.find(wallet => wallet.walletClientType === 'privy');
                if (!embeddedWallet) {
                    throw new Error('Embedded wallet not found');
                }

                // 获取provider
                const provider = await embeddedWallet.getEthersProvider();

                // 创建合约实例
                const bountyContract = new ethers.Contract(addrBountySmartContract, contractBountyABI, provider.getSigner());

                // 准备交易参数
                const challengeBonusWei = ethers.utils.parseEther(challengeBonus.toString());

                // 估算gas
                const gasEstimate = await bountyContract.estimateGas.createChallenge(videoId, challengeDescription, { value: challengeBonusWei });

                // 发送交易
                const tx = await bountyContract.createChallenge(videoId, challengeDescription, {
                    value: challengeBonusWei,
                    gasLimit: gasEstimate.mul(120).div(100)
                });

                console.log('Challenge creation transaction sent:', tx.hash);

                // 等待交易确认
                const receipt = await tx.wait();
                console.log('Challenge creation transaction confirmed:', receipt.transactionHash);
            }

            // 首先执行 creation 请求
            const creationResponse = await api.setCreation(
                sessionCreation,
                userLoginData.userid,
                videoId,
                title,
                description,
                JSON.stringify(tags.map(tag => tag.value)),
                challengeNftId || '',
                visibility ? '0' : '1',
                price.toString(),
                isAudio,
                isAudio === '1' ? audioDuration : 0
            );

            if (!creationResponse.success) {
                throw new Error(creationResponse.msg || 'An error occurred during video creation');
            }

            // 如果选择了房间，在 creation 成功后再添加视频到房间
            if (selectedRoom) {
                const addToRoomResponse = await api.setRoomVideoAdd(selectedRoom, videoId, true);
                if (!addToRoomResponse.success) {
                    console.error("Failed to add video to room:", addToRoomResponse.msg);
                    // 可以选择是否抛出错误，或者只记录日志
                    // throw new Error("Failed to add video to room");
                }
            }

            setIsLoading(false);
            setShowSuccessModal(true);

        } catch (error: unknown) {
            setIsLoading(false);
            setShowErrorModal(true);
            if (error instanceof Error) {
                setErrorMessage(error.message || 'An unknown error occurred');
            } else {
                setErrorMessage('An unknown error occurred');
            }
        }
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <>
                        <p>{stepContents[currentStep].content}</p>
                        <div className="mt-4">
                            <FilePond
                                ref={filePondRef}
                                files={files.map(file => file.file)}
                                onupdatefiles={(fileItems) => {
                                    setFiles(fileItems);
                                    if (fileItems.length === 0) {
                                        setIsUploadComplete(false);
                                    }
                                }}
                                allowMultiple={false}
                                maxFiles={1}
                                acceptedFileTypes={['video/*', 'audio/mpeg', 'audio/mp3']}
                                minFileSize="1MB"
                                maxFileSize="2000MB"
                                credits={false}
                                server={{
                                    process: (_fieldName, file, _metadata, load, error, progress, abort) => {
                                        getSessionAndUploadToS3(
                                            file as File,
                                            load,
                                            error,
                                            (_percentage, chunk, total) => {
                                                progress(true, chunk, total);
                                            },
                                            abort
                                        );
                                        return {
                                            abort: () => {
                                                abort();
                                                setIsUploadComplete(false);
                                            }
                                        };
                                    },
                                }}
                                labelIdle={t('creation.upload.labelIdle')}
                                labelFileProcessing={t('creation.upload.processing')}
                                labelFileProcessingComplete={t('creation.upload.complete')}
                                labelFileProcessingAborted={t('creation.upload.aborted')}
                                labelFileProcessingError={t('creation.upload.error')}
                                labelFileTypeNotAllowed={t('creation.upload.invalidType')}
                                labelTapToCancel={t('creation.upload.tapToCancel')}
                                labelTapToRetry={t('creation.upload.tapToRetry')}
                                labelTapToUndo={t('creation.upload.tapToUndo')}
                            />
                        </div>
                        {showUploadWarning && (
                            <p className="text-red-500 mt-2">Please wait for the upload to complete before proceeding.</p>
                        )}
                    </>
                );
            case 1:
                return (
                    <>
                        <p>{stepContents[currentStep].content}</p>
                        {imageSizeError && (
                            <p className="text-red-500 mt-2">{imageSizeError}</p>
                        )}
                        <div className="mt-4">
                            {croppedImageUrl ? (
                                <div>
                                    <h3 className="text-lg font-semibold">{t('creation.imagePreview')}</h3>
                                    <img src={croppedImageUrl} alt="Cropped cover" className="mt-2 max-w-full h-auto" />
                                    <button
                                        onClick={() => {
                                            setCroppedImageUrl(null);
                                        }}
                                        className="mt-4 px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-800"
                                    >
                                        {t('creation.modifyImage')}
                                    </button>
                                </div>
                            ) : (
                                coverImage && !imageSizeError && (
                                    <ImageCropper
                                        coverImage={coverImage}
                                        MIN_WIDTH={MIN_WIDTH}
                                        MIN_HEIGHT={MIN_HEIGHT}
                                        onSaveCrop={(croppedImage) => setCroppedImageUrl(croppedImage)}
                                        onReupload={handleReupload}
                                        initialAspectRatio={16 / 9} 
                                        {...({} as any)}
                                    />
                                )
                            )}
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                accept="image/*,video/*"
                                className="hidden"
                            />
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <Input
                            label={t('creation.form.title')}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="mb-4"
                        />
                        <Textarea
                            label={t('creation.form.description')}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="mb-4"
                        />
                        <div className="flex items-center space-x-4 pb-4">
                            <label className="whitespace-nowrap font-bold">{t('creation.form.tags')}</label>
                            <div className="flex-grow">
                                <CreatableSelect<TagOption, true>
                                    isMulti
                                    options={defaultTags}
                                    value={tags}
                                    onChange={handleTagChange}
                                    className="basic-multi-select z-[9999]"
                                    classNamePrefix="select"
                                    formatCreateLabel={(inputValue: string) => 
                                        t('creation.form.createTag', { value: inputValue })}
                                />
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="mr-2 font-bold">{t('creation.form.access')}</span>
                            <Switch
                                isSelected={visibility}
                                onValueChange={setVisibility}
                            />
                            <span className="ml-2">
                                {visibility ? t('creation.form.public') : t('creation.form.private')}
                            </span>
                        </div>
                    </>
                );
            case 3:
                return (
                    <>
                        <div className="mb-4">
                            <Slider
                                label={t('creation.review.videoPrice')}
                                size="sm"
                                step={0.01}
                                maxValue={2}
                                minValue={0}
                                value={price}
                                getValue={(payment) => `${payment} POL`}
                                onChange={(value) => setPrice(Number(value))}
                                classNames={{
                                    base: "max-w-md gap-3",
                                    track: "border-s-secondary-100",
                                    filler: "bg-gradient-to-r from-secondary-100 to-secondary-500"
                                }}
                                renderThumb={(props) => (
                                    <div
                                        {...props}
                                        className="group p-1 top-1/2 bg-background border-small border-default-200 dark:border-default-400/50 shadow-medium rounded-full cursor-grab data-[dragging=true]:cursor-grabbing"
                                    >
                                        <span className="transition-transform bg-gradient-to-br shadow-small from-secondary-100 to-secondary-500 rounded-full w-5 h-5 block group-data-[dragging=true]:scale-80" />
                                    </div>
                                )}
                            />
                        </div>
                        <Splitter
                            splitterOptions={splitterOptions}
                            setSplitterOptions={setSplitterOptions}
                        />
                        <div className="advanced-settings">
                            <p className="text-sm text-gray-600 mb-2">
                                {t('creation.form.roomSelect.notice')}
                            </p>
                            <Select
                                label={t('creation.form.roomSelect.label')}
                                placeholder={t('creation.form.roomSelect.placeholder')}
                                selectedKeys={selectedRoom ? [selectedRoom] : []}
                                onChange={(event) => handleRoomSelect({ target: event.target.value })}
                            >
                                {rooms.map((room) => (
                                    <SelectItem key={room.id} value={room.id}>
                                        {room.name}
                                    </SelectItem>
                                ))}
                            </Select>
                        </div>
                        <div className="mt-4">
                            <Switch
                                isSelected={createChallenge}
                                onValueChange={setCreateChallenge}
                                size="sm"
                            >
                                {t('creation.form.challenge.create')}
                            </Switch>
                        </div>
                        {createChallenge && (
                            <>
                                <div className="mt-4">
                                    <Slider
                                        label={t('creation.form.challenge.bonus')}
                                        size="sm"
                                        step={1}
                                        maxValue={100}
                                        minValue={1}
                                        value={challengeBonus}
                                        getValue={(value) => `${value} POL`}
                                        onChange={(value) => setChallengeBonus(Number(value))}
                                        classNames={{
                                            base: "max-w-md gap-3",
                                            track: "border-s-secondary-100",
                                            filler: "bg-gradient-to-r from-secondary-100 to-secondary-500"
                                        }}
                                        renderThumb={(props) => (
                                            <div
                                                {...props}
                                                className="group p-1 top-1/2 bg-background border-small border-default-200 dark:border-default-400/50 shadow-medium rounded-full cursor-grab data-[dragging=true]:cursor-grabbing"
                                            >
                                                <span className="transition-transform bg-gradient-to-br shadow-small from-secondary-100 to-secondary-500 rounded-full w-5 h-5 block group-data-[dragging=true]:scale-80" />
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Textarea
                                        label={t('creation.form.challenge.description')}
                                        value={challengeDescription}
                                        onChange={(e) => setChallengeDescription(e.target.value)}
                                        placeholder={t('creation.form.challenge.descPlaceholder')}
                                        isRequired={createChallenge}
                                        isInvalid={createChallenge && !isChallengeDescriptionValid}
                                        errorMessage={createChallenge && !isChallengeDescriptionValid ? 
                                            t('creation.form.challenge.description') : ""}
                                    />
                                </div>
                            </>
                        )}
                    </>
                );
            case 4:
                // 获取选中房间的信息
                const selectedRoomInfo = rooms.find(room => String(room.id) === String(selectedRoom));

                return (
                    <>
                        <div className="space-y-4">
                            {croppedImageUrl && (
                                <div>
                                    <img src={croppedImageUrl} alt="Video cover"
                                         className="mt-2 max-w-full h-auto rounded-lg shadow-md"/>
                                </div>
                            )}
                            <p><strong>{t('creation.review.title')}:</strong> {title}</p>
                            <p><strong>{t('creation.review.description')}:</strong> {description}</p>
                            <p><strong>{t('creation.review.tags')}:</strong> {tags.map(tag => tag.label).join(', ')}</p>
                            <p><strong>{t('creation.review.access')}:</strong> {visibility ? t('creation.form.public') : t('creation.form.private')}</p>
                            <p><strong>{t('creation.review.price')}:</strong> {price} POL</p>
                            <p><strong>{t('creation.review.splitterAddresses')}:</strong></p>
                            <ul className="list-disc pl-5">
                                {splitterOptions.map((option, index) => (
                                    <li key={index}>{option.value}</li>
                                ))}
                            </ul>
                            <p>
                                <strong>{t('creation.review.selectedRoom')}:</strong> {
                                    selectedRoomInfo 
                                        ? `${selectedRoomInfo.name}`
                                        : t('creation.review.none')
                                }
                            </p>
                            <p>
                                <strong>{t('creation.review.createChallenge')}:</strong> {
                                    createChallenge ? t('creation.review.yes') : t('creation.review.no')
                                }
                            </p>
                            {createChallenge && (
                                <>
                                    <p><strong>{t('creation.form.challenge.bonus')}:</strong> {challengeBonus} POL</p>
                                    <p><strong>{t('creation.form.challenge.description')}:</strong> {challengeDescription}</p>
                                </>
                            )}
                        </div>
                    </>
                );
            default:
                return <p>{stepContents[currentStep].content}</p>;
        }
    };

    useEffect(() => {
        if (ready) {
            setIsCheckingAuth(false);
        }
    }, [ready]);

    useEffect(() => {
        if (currentStep === 1 && files.length > 0 && !croppedImageUrl) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            extractFirstFrame(files[0].file);
        }
    }, [currentStep, files, croppedImageUrl]);

    useEffect(() => {
        if (currentStep === 2) {
            setIsStep3Valid(title.trim() !== '' && description.trim() !== '');
        }
    }, [currentStep, title, description]);

    useEffect(() => {
        if (showSuccessModal) {
            onSuccessModalOpen();
        }
    }, [onSuccessModalOpen, showSuccessModal]);

    useEffect(() => {
        fetchRooms();
    }, []);

    useEffect(() => {
        if (nftid) {
            setChallengeNftId(nftid);
        }
    }, [nftid]);

    useEffect(() => {
        if (createChallenge) {
            setIsChallengeDescriptionValid(challengeDescription.trim() !== '');
        } else {
            setIsChallengeDescriptionValid(true);
        }
    }, [createChallenge, challengeDescription]);

    useEffect(() => {
        const newVideoId = generateUUIDWithoutHyphens();
        setVideoId(newVideoId);
        console.log('Generated videoId:', newVideoId);
    }, []);

    const fetchRooms = async () => {
        try {
            const response: ApiResponse<RoomData> = await api.getRoom(userLoginData.userid);
            if (response.success) {
                const allRooms = [...response.data.joined, ...response.data.created];
                setRooms(allRooms);
            } else {
                console.error("Failed to fetch rooms:", response.msg);
            }
        } catch (error) {
            console.error("An error occurred while fetching rooms:", error);
        }
    };

    const handleRoomSelect = (e: {
        target: { value: string } | string
    }) => {
        // 确保我们能正确获取选中的值
        const selectedRoomId = typeof e.target === 'string' ? e.target : e.target.value;
        
        setSelectedRoom(selectedRoomId);
        
        if (selectedRoomId) {
            // 将 selectedRoomId 转换为相同类型进行比较
            const selectedRoom = rooms.find(room => String(room.id) === String(selectedRoomId));
            console.log('Selected Room:', selectedRoom); // 调试日志
            
            if (selectedRoom?.address) {
                console.log('Room Address:', selectedRoom.address); // 调试日志
                
                // 检查address是否已经存在于splitter中
                const addressExists = splitterOptions.some(option => 
                    option.value.toLowerCase() === selectedRoom.address.toLowerCase()
                );
                
                // 如果address不存在，则添加到splitter中
                if (!addressExists) {
                    const newSplitterOptions = [
                        ...splitterOptions,
                        {
                            value: selectedRoom.address,
                            label: `Room: ${selectedRoom.name}`
                        }
                    ];
                    setSplitterOptions(newSplitterOptions);
                }
            }
        }
    };

    if (isCheckingAuth) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                <p>{t('creation.auth.checking')}</p>
            </div>
        );
    }

    if (!authenticated) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <Icon
                    icon="lets-icons:wallet-alt"
                    width={48}
                    className={"mb-8"}
                />
                <h1 className="text-xl font-bold mb-10">{t('creation.auth.connect.title')}</h1>
                <Button
                    color="primary"
                    size="lg"
                    onClick={login}
                >
                    {t('creation.auth.connect.button')}
                </Button>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center w-full max-w-4xl mx-auto px-0 sm:px-4">
            {!authenticated ? (
                <div className="flex flex-col items-center justify-center h-screen">
                    <h1 className="text-2xl font-bold mb-4">Video Upload</h1>
                    <p className="mb-8">Please log in to continue</p>
                    <Button
                        color="primary"
                        size="lg"
                        onClick={login}
                    >
                        LogIn
                    </Button>
                </div>
            ) : (
                <>
                    <div className="w-full overflow-x-auto pb-4">
                        <div className="min-w-max">
                            <HorizontalSteps
                                currentStep={currentStep}
                                steps={stepContents}
                                onStepChange={handleStepChange}
                            />
                        </div>
                    </div>
                    <div className="mt-8 w-full p-4 border rounded-lg">
                        <h2 className="text-xl font-bold mb-4">{stepContents[currentStep].title}</h2>
                        {renderStepContent()}
                    </div>

                    {isLoading && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <div className="bg-white p-8 rounded-lg text-center">
                                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 mx-auto mb-4"></div>
                                <p className="text-xl font-semibold">{loadingStatus}</p>
                            </div>
                        </div>
                    )}

                    <Modal
                        isOpen={isSuccessModalOpen}
                        onClose={onSuccessModalClose}
                        isDismissable={false}
                        hideCloseButton
                    >
                        <ModalContent>
                            {() => (
                                <>
                                    <ModalHeader className="flex flex-col gap-1">
                                        {t('creation.modal.success.title')}
                                    </ModalHeader>
                                    <ModalBody>
                                        <p>{t('creation.modal.success.message')}</p>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" onPress={handleViewVideo}>
                                            {t('creation.modal.success.viewVideo')}
                                        </Button>
                                    </ModalFooter>
                                </>
                            )}
                        </ModalContent>
                    </Modal>

                    <Modal
                        isOpen={showErrorModal}
                        onClose={() => setShowErrorModal(false)}
                    >
                        <ModalContent>
                            {(onClose) => (
                                <>
                                    <ModalHeader className="flex flex-col gap-1">
                                        {t('creation.modal.error.title')}
                                    </ModalHeader>
                                    <ModalBody>
                                        <p>{errorMessage}</p>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="danger" variant="light" onPress={onClose}>
                                            {t('creation.modal.error.close')}
                                        </Button>
                                    </ModalFooter>
                                </>
                            )}
                        </ModalContent>
                    </Modal>

                    <div className="flex justify-between w-full mt-4">
                        <Button
                            onClick={handlePrevious}
                            disabled={currentStep === 0 || (currentStep === 1 && step1Completed)}
                            color="primary"
                            variant="bordered"
                            className="disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {t('creation.navigation.previous')}
                        </Button>
                        {currentStep === stepContents.length - 1 ? (
                            <Button
                                onClick={handleSubmit}
                                disabled={isLoading || !isChallengeDescriptionValid}
                                color="success"
                                className="disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {isLoading ? t('creation.navigation.submitting') : t('creation.navigation.submit')}
                            </Button>
                        ) : (
                            <Button
                                onClick={handleNext}
                                disabled={
                                    (currentStep === 0 && !isUploadComplete) ||
                                    (currentStep === 1 && !croppedImageUrl) ||
                                    (currentStep === 2 && !isStep3Valid) ||
                                    (currentStep === 3 && !isChallengeDescriptionValid)
                                }
                                color="primary"
                                className="disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {t('creation.navigation.next')}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
