import { useEffect, useState, useCallback } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { Card, CardHeader, CardBody, Avatar, Spacer, Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';
import { api, RoomData } from '../services/api';
import VideoList, { Video } from './VideoList';
import { Icon } from "@iconify/react";
import SpinnerV from "./SpinnerV";
import { usePrivy, useWallets } from '@privy-io/react-auth';
import { ethers } from 'ethers';
import { useSnackbar } from 'notistack';
import {polygon} from "wagmi/chains";
import { useTranslation } from 'react-i18next';

interface LocationState {
    scrollPosition?: number;
}

const RoomDetail = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const userLoginData = JSON.parse(localStorage.getItem('userLoginData') || '{}');
    const userId = userLoginData.userid;
    const navigate = useNavigate();
    const location = useLocation();
    const { login, authenticated, user } = usePrivy();
    const { wallets } = useWallets();
    const { enqueueSnackbar } = useSnackbar();

    const [showJoinModal, setShowJoinModal] = useState(false);
    const [showLeaveModal, setShowLeaveModal] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showDestroyModal, setShowDestroyModal] = useState(false);
    const [buttonState, setButtonState] = useState({
        isLoading: true,
        type: '' as 'join' | 'leave' | 'destroy' | ''
    });

    const [roomState, setRoomState] = useState({
        isLoading: true,
        isJoined: false,
        isCreator: false,
        roomData: null as RoomData | null
    });

    const [pagination, setPagination] = useState({
        currentPage: 0,
        totalPages: 1,
        isLoading: false
    });

    const updateRoomState = useCallback((
        updates: Partial<typeof roomState> | ((prev: typeof roomState) => typeof roomState)
    ) => {
        setRoomState(prev => {
            if (typeof updates === 'function') {
                return updates(prev);
            }
            return { ...prev, ...updates };
        });
    }, []);

    const checkRoomStatus = useCallback(async () => {
        if (id && authenticated) {
            try {
                const response = await api.getRoom(userId);
                if (response.success) {
                    const isJoined = response.data.joined.some(room => String(room.id) === String(id));
                    const isCreator = response.data.created.some(room => String(room.id) === String(id));
                    updateRoomState({ isJoined, isCreator });
                    return { isJoined, isCreator };
                } else {
                    if (response.errcode === 1011) {
                        enqueueSnackbar(t('room.errors.roomClosed'), {
                            variant: "warning",
                            autoHideDuration: 5000
                        });
                        setTimeout(() => {
                            navigate('/');
                        }, 2000);
                        return null;
                    }
                    enqueueSnackbar(response.msg || t('room.errors.checkStatusFailed'), {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                    return null;
                }
            } catch (error) {
                console.error('Error checking room status:', error);
                enqueueSnackbar("Failed to check room status", {
                    variant: "error",
                    autoHideDuration: 3000
                });
                return null;
            }
        }
        return null;
    }, [id, authenticated, userId, updateRoomState, enqueueSnackbar, navigate, t]);

    const fetchRoomDetails = async (page: number = 0) => {
        if (id) {
            updateRoomState((prev: typeof roomState) => ({ 
                ...prev,
                isLoading: true,
                roomData: prev.roomData ? {
                    ...prev.roomData,
                    room_video: {} as typeof prev.roomData.room_video
                } : null
            }));
            setPagination(prev => ({ ...prev, isLoading: true }));
            
            try {
                await new Promise(resolve => setTimeout(resolve, 100));
                
                const response = await api.getRoomDetails(userId, id, page);
                if (response.success) {
                    updateRoomState((prev: typeof roomState) => ({
                        ...prev,
                        roomData: {
                            ...response.data,
                            info: prev.roomData?.info || response.data.info,
                            users_info: prev.roomData?.users_info || response.data.users_info
                        } as RoomData,
                        isLoading: false
                    }));
                    setPagination(prev => ({
                        ...prev,
                        currentPage: page,
                        totalPages: response.data.total_pages,
                        isLoading: false
                    }));
                    if (page === 0) {
                        document.title = response.data.info.name;
                    }
                } else {
                    if (response.errcode === 1011) {
                        enqueueSnackbar("This room has been closed", {
                            variant: "warning",
                            autoHideDuration: 5000
                        });
                        setTimeout(() => {
                            navigate('/');
                        }, 2000);
                    } else {
                        enqueueSnackbar(response.msg || "Failed to load room details", {
                            variant: "error",
                            autoHideDuration: 3000
                        });
                    }
                    updateRoomState({ isLoading: false });
                }
            } catch (error) {
                updateRoomState({ isLoading: false });
                setPagination(prev => ({ ...prev, isLoading: false }));
                // ... error handling ...
            }
        }
    };

    const initializeRoom = async () => {
        setButtonState({ isLoading: true, type: '' });
        await fetchRoomDetails();
        const status = await checkRoomStatus();
        if (status) {
            setButtonState({
                isLoading: false,
                type: status.isCreator ? 'destroy' : (status.isJoined ? 'leave' : 'join')
            });
        }
    };

    useEffect(() => {
        initializeRoom();
    }, [userId, id, checkRoomStatus, updateRoomState, navigate, enqueueSnackbar, t]);

    const joinRoom = useCallback(async () => {
        if (!id) return;

        if (!authenticated) {
            enqueueSnackbar(t('room.errors.connectWalletFirst'), { variant: 'warning' });
            login();
            return;
        }

        // 确保钱包已初始化
        if (!wallets.length) {
            enqueueSnackbar(t('room.errors.walletInitializing'), { variant: 'info' });
            return;
        }

        // 专门获取 Privy 内嵌钱包
        const embeddedWallet = wallets.find(wallet => wallet.walletClientType === 'privy');
        
        if (!embeddedWallet) {
            enqueueSnackbar(t('room.errors.embeddedWalletNotFound'), { variant: 'error' });
            return;
        }

        try {
            // 使用 Privy provider 获取余额
            const provider = await embeddedWallet.getEthereumProvider();
            
            // 确保切换到 Polygon 网络
            try {
                await provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: polygon.id }], // Polygon 的 chainId
                });
            } catch (switchError: any) {
                // 如果网络没有添加，则添加 Polygon 网络
                if (switchError.code === 4902) {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [{
                            chainId: polygon.id,
                            chainName: polygon.name,
                            nativeCurrency: {
                                name: 'POL',
                                symbol: 'POL',
                                decimals: 18
                            },
                            rpcUrls: polygon.rpcUrls.default.http,
                            blockExplorerUrls: [polygon.blockExplorers.default.url]
                        }]
                    });
                }
            }

            const ethersProvider = new ethers.providers.Web3Provider(provider);
            const signer = ethersProvider.getSigner();
            
            // 获取钱包地址和余额
            const address = await signer.getAddress();
            const balance = await ethersProvider.getBalance(address);
            
            console.log('Using embedded wallet:', address);
            console.log('Balance:', ethers.utils.formatEther(balance), 'MATIC');
            
            // 检查余额是否足够
            const requiredAmount = ethers.utils.parseEther('0.1');
            if (balance.lt(requiredAmount)) {
                throw new Error(`Insufficient balance. You need at least 0.1 MATIC. Current balance: ${ethers.utils.formatEther(balance)} MATIC`);
            }
            
            // 再次确认网络
            const network = await ethersProvider.getNetwork();
            console.log('Current network:', network);
            
            if (network.chainId !== 137) {
                throw new Error('Please switch to Polygon network');
            }
            
            // 估算 gas 费用
            const data = ethers.utils.toUtf8Bytes(`[JR][${userId}][${id}]`);
            const gasEstimate = await signer.estimateGas({
                to: '0x615F0853B746B4cF44344d0A79e5ab58D2376752',
                value: ethers.utils.parseEther('0.1'),
                data: data,
            });
            
            console.log('Estimated Gas:', gasEstimate.toString());
            
            const tx = await signer.sendTransaction({
                to: '0x615F0853B746B4cF44344d0A79e5ab58D2376752',
                value: ethers.utils.parseEther('0.1'),
                data: data,
                gasLimit: gasEstimate.mul(120).div(100), // 添加20%的gas限制作为缓冲
            });

            await tx.wait();

            // 调用 setRoomEntryCheck 接口
            const response = await api.setRoomEntryCheck(userId, tx.hash);
            if (!response.success) {
                throw new Error(response.msg || "Failed to verify room entry");
            }
        } catch (error) {
            console.error('Transaction Error:', error);
            throw error;
        }
    }, [id, authenticated, login, wallets, enqueueSnackbar, t]);

    const leaveRoom = useCallback(async () => {
        if (!user?.id || !id) return;
        const response = await api.setRoomUnfollow(userId, id);
        if (!response.success) {
            throw new Error(response.msg || "Failed to leave room");
        }
    }, [user?.id, id]);

    const destroyRoom = useCallback(async () => {
        if (!id) return;
        const response = await api.setRoomDestroy(id);
        if (!response.success) {
            throw new Error(response.msg || "Failed to destroy room");
        }
    }, [id]);

    const handleJoinLeaveDestroyRoom = useCallback(async () => {
        if (!authenticated) {
            enqueueSnackbar("Please log in to join, leave, or destroy a room", { variant: "info" });
            login();
            return;
        }

        if (roomState.isCreator) {
            setShowDestroyModal(true);
        } else if (roomState.isJoined) {
            setShowLeaveModal(true);
        } else {
            setShowJoinModal(true);
        }
    }, [authenticated, login, enqueueSnackbar, roomState.isJoined, roomState.isCreator]);

    const confirmJoinRoom = useCallback(async () => {
        if (isProcessing) return;
        
        try {
            setIsProcessing(true);
            await joinRoom();
            setShowJoinModal(false);
            
            // 重新检查房间状态
            const status = await checkRoomStatus();
            if (status) {
                setButtonState({
                    isLoading: false,
                    type: status.isCreator ? 'destroy' : (status.isJoined ? 'leave' : 'join')
                });
            }
            
            enqueueSnackbar(t('room.success.joined'), {
                variant: "success",
                autoHideDuration: 3000
            });
        } catch (error: any) {
            console.error('Join room error:', error);
            setShowJoinModal(false);  // 只在错误时关闭模态框
            
            if (error instanceof Error) {
                if (error.message.includes('insufficient funds')) {
                    enqueueSnackbar(t('room.errors.insufficientBalance'), {
                        variant: "warning",
                        autoHideDuration: 5000
                    });
                } else {
                    enqueueSnackbar(t('common.errors.tryAgainLater'), {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            } else {
                enqueueSnackbar("An unexpected error occurred. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        } finally {
            setIsProcessing(false);
        }
    }, [joinRoom, checkRoomStatus, enqueueSnackbar, isProcessing, t]);

    const confirmLeaveRoom = useCallback(async () => {
        try {
            setIsProcessing(true);
            await leaveRoom();
            setShowLeaveModal(false);
            // 重新检查房间状态
            const status = await checkRoomStatus();
            if (status) {
                setButtonState({
                    isLoading: false,
                    type: status.isCreator ? 'destroy' : (status.isJoined ? 'leave' : 'join')
                });
            }
            enqueueSnackbar(t('room.success.left'), {
                variant: "success",
                autoHideDuration: 3000
            });
        } catch (error) {
            console.error('Leave room error:', error);
            enqueueSnackbar(t('room.errors.leaveFailed'), {
                variant: "error",
                autoHideDuration: 3000
            });
        } finally {
            setIsProcessing(false);
        }
    }, [leaveRoom, checkRoomStatus, enqueueSnackbar, t]);

    const confirmDestroyRoom = async () => {
        setIsProcessing(true);
        setShowDestroyModal(false);  // Close the modal immediately
        try {
            await destroyRoom();
            enqueueSnackbar(t('room.success.deleted'), { 
                variant: "success",
                autoHideDuration: 3000
            });
            navigate('/');  // Return to home page after destruction
        } catch (error) {
            console.error('Error:', error);
            if (error instanceof Error) {
                if (error.message.includes('insufficient funds')) {
                    enqueueSnackbar("Insufficient balance. Please add funds to your wallet and try again.", {
                        variant: "warning",
                        autoHideDuration: 5000
                    });
                } else {
                    enqueueSnackbar("Oops! Something went wrong. Please try again later.", {
                        variant: "error",
                        autoHideDuration: 3000
                    });
                }
            } else {
                enqueueSnackbar("An unexpected error occurred. Please try again later.", {
                    variant: "error",
                    autoHideDuration: 3000
                });
            }
        } finally {
            setIsProcessing(false);
        }
    };

    const handleVideoClick = (videoId: string) => {
        navigate(location.pathname + location.search, {
            state: { scrollPosition: window.scrollY } as LocationState
        });

        navigate(`/video/${videoId}`);
    };

    useEffect(() => {
        const state = location.state as LocationState;
        if (state && typeof state.scrollPosition === 'number') {
            window.scrollTo(0, state.scrollPosition);
        }
    }, [location]);

    const { roomData } = roomState;

    if (!roomData) {
        return (
            <div className="flex justify-center items-center h-screen">
                <SpinnerV />
            </div>
        );
    }

    const { info, users_info, room_video } = roomData;

    const transformedVideos: Video[] = Object.values(room_video).map(video => {
        const videoData = Object.values(video)[0];
        return {
            id: videoData.videoid,
            videoid: videoData.videoid,
            thumbnail: undefined,
            timestamp: videoData.timestamp,
            price: videoData.price || 0,
            duration: videoData.duration || 0,
            view: videoData.view || 0,
            comment: videoData.comment || 0,
            tags: [],
            is_up: false,
            author: videoData.author || '',
            note: videoData.note || '',
            title: videoData.title || '',
            metamask: '',
            playlist: [],
            bywho: video.bywho?.author || '',
            winner_videoid: videoData.winner_videoid || '',
            winner_original_videoid: videoData.winner_original_videoid || '',
            is_followed: false,
            address: '',
            nickname: videoData.author || '',
            is_audio: videoData.is_audio || 0
        };
    });

    return (
        <div className="p-4">
            <Card className="mb-4">
                <CardHeader className="flex justify-between items-center">
                    <div className="flex items-center">
                        <Link to="/" className="mr-2">
                            <Icon icon="mdi:home" width="24" height="24" />
                        </Link>
                        <h2 className="sm:text-2xl font-bold">{info.name}</h2>
                    </div>
                    <div className="w-[120px] h-[40px] flex items-center justify-end">
                        {buttonState.isLoading ? (
                            <div className="w-[24px] h-[24px]">
                                <Icon icon="eos-icons:three-dots-loading" width="24" height="24" />
                            </div>
                        ) : (buttonState.type && buttonState.type !== 'destroy') && (
                            <Button
                                color={buttonState.type === 'leave' ? "secondary" : "primary"}
                                onClick={handleJoinLeaveDestroyRoom}
                                onTouchStart={(e) => {
                                    e.preventDefault();
                                    handleJoinLeaveDestroyRoom();
                                }}
                                disabled={isProcessing}
                                className="w-full h-full"
                            >
                                {isProcessing ? (
                                    <SpinnerV size={20} />
                                ) : (
                                    buttonState.type === 'leave' ? t('room.actions.leave') : t('room.actions.join')
                                )}
                            </Button>
                        )}
                    </div>
                </CardHeader>
                <CardBody>
                    <p>{info.description}</p>
                    <Spacer y={2} />
                    <div className="flex flex-wrap gap-2">
                        {Object.values(users_info).map((user) => (
                            <Link
                                key={user.userid}
                                to={`/user/${user.userid}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Avatar
                                    src={`https://robohash.org/${user.avatar}?size=300x300&set=set5`}
                                    size="sm"
                                />
                            </Link>
                        ))}
                    </div>
                </CardBody>
            </Card>

            <VideoList
                videos={transformedVideos}
                onVideoClick={handleVideoClick}
            />

            {/* 加载状态 */}
            {pagination.isLoading && (
                <div className="flex justify-center items-center my-4">
                    <SpinnerV />
                </div>
            )}

            {/* 分页按钮，只在非加载状态显示 */}
            {!pagination.isLoading && (
                <div className="flex justify-center gap-2 mt-4">
                    {Array.from({ length: pagination.totalPages }, (_, i) => (
                        <Button
                            key={i}
                            size="sm"
                            variant={pagination.currentPage === i ? "solid" : "bordered"}
                            color={pagination.currentPage === i ? "primary" : "default"}
                            onClick={() => fetchRoomDetails(i)}
                            onTouchStart={(e) => {
                                e.preventDefault();
                                fetchRoomDetails(i);
                            }}
                            disabled={pagination.isLoading}
                        >
                            {i + 1}
                        </Button>
                    ))}
                </div>
            )}

            <Modal isOpen={showJoinModal} onClose={() => !isProcessing && setShowJoinModal(false)}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t('room.modal.join.title')}</ModalHeader>
                            <ModalBody>
                                <p>{t('room.modal.join.message')}</p>
                            </ModalBody>
                            <ModalFooter>
                                {!isProcessing && (
                                    <Button 
                                        color="danger" 
                                        variant="light" 
                                        onPress={onClose}
                                    >
                                        {t('common.cancel')}
                                    </Button>
                                )}
                                <Button 
                                    color="primary" 
                                    onPress={confirmJoinRoom}
                                    disabled={isProcessing}
                                >
                                    {isProcessing ? (
                                        <div className="flex items-center gap-2">
                                            <SpinnerV size={20} />
                                            <span>{t('room.modal.join.processing')}</span>
                                        </div>
                                    ) : t('room.actions.join')}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={showLeaveModal} onClose={() => setShowLeaveModal(false)}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t('room.modal.leave.title')}</ModalHeader>
                            <ModalBody>
                                <p>{t('room.modal.leave.message')}</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" variant="light" onPress={onClose}>
                                    {t('common.cancel')}
                                </Button>
                                <Button color="danger" onPress={confirmLeaveRoom}>
                                    {t('room.actions.leave')}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={showDestroyModal} onClose={() => setShowDestroyModal(false)}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">{t('room.modal.delete.title')}</ModalHeader>
                            <ModalBody>
                                <p>{t('room.modal.delete.message')}</p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" variant="light" onPress={onClose}>
                                    {t('common.cancel')}
                                </Button>
                                <Button color="danger" onPress={confirmDestroyRoom}>
                                    {t('room.actions.delete')}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
};

export default RoomDetail;
