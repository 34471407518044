import ReactDOM from "react-dom/client";
import { NextUIProvider } from "@nextui-org/react";
import App from "./App";
import "./styles/styles.css";
import 'react-toastify/dist/ReactToastify.css';
import './i18n/config';

// todo 0.1

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement);

root.render(
    <NextUIProvider>
        <App />
    </NextUIProvider>
);