import React from 'react';
import '../assets/spinner.css';

interface SpinnerProps {
  size?: number;
  color?: string;
}

const SpinnerV: React.FC<SpinnerProps> = ({ size = 32, color = '#ED3169' }) => {
  return (
    <div 
      className="svg-spinners-v-watch" 
      style={{ 
        fontSize: `${size}px`,
        color: color
      }}
    />
  );
};

export default SpinnerV;