import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Avatar, ScrollShadow, Spacer, Tooltip, Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Input, Textarea, Card, CardBody, CardFooter, Divider, Badge, Switch } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { useMediaQuery } from "usehooks-ts";
import { VIcon } from "./components/Banner";
import { cn } from "./utils/cn";
import Sidebar, { SidebarItem } from "./components/Sidebar";
import { api, API_BASE_URL, Room } from "./services/api";
import {PrivyProvider, usePrivy, useWallets} from '@privy-io/react-auth';
import VideoList, { Video } from "./components/VideoList";
import {useNavigate, useParams, useLocation, Routes, Route, BrowserRouter} from 'react-router-dom';
import IcebreakersContent from "./components/IcebreakersPage";
import VideoDetailOverlay from "./components/VideoDetailOverlay";
import Creation from "./components/Creation";
import RoomDetail from "./components/RoomDetail";
import UserProfile from "./components/UserProfile";
import {mainnet, polygon} from "wagmi/chains";
import {WagmiProvider} from "wagmi";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import { config } from './wagmiConfig';
import SpinnerV from "./components/SpinnerV";
import { useSnackbar, SnackbarProvider } from 'notistack';
import { ethers } from 'ethers';
import { QRCodeSVG } from 'qrcode.react';
import RoomList from "./components/RoomList";
import { useTranslation } from 'react-i18next';
import './i18n/config';
import LanguageSwitcher from './components/LanguageSwitcher';
const queryClient = new QueryClient()

// todo 0.12

function getDefaultSidebarItems(t: (key: string) => string): SidebarItem[] {
    return [
        {
            key: "feed",
            title: t('sidebar.feed'),
            items: [
                {
                    key: "newest",
                    icon: "material-symbols:fiber-new-rounded",
                    title: t('sidebar.newest'),
                },
                {
                    key: "hot7d",
                    icon: "ic:baseline-whatshot",
                    title: t('sidebar.hot7d'),
                },
                {
                    key: "home",
                    icon: "solar:home-2-linear",
                    title: t('sidebar.home'),
                },
                {
                    key: "icebreakers",
                    icon: "carbon:trophy",
                    title: t('sidebar.icebreakers'),
                },
                {
                    key: "creation",
                    icon: "mdi:creation-outline",
                    title: t('sidebar.creation'),
                    endContent: (
                        <Icon className="text-default-400" icon="grommet-icons:form-upload" width={24} />
                    ),
                },
                {
                    key: "rooms",
                    icon: "material-symbols:nest-multi-room",
                    title: t('sidebar.rooms'),
                },
            ],
        },
    ];
}

function createRoomItems(rooms: Room[], type: string, t: (key: string) => string): SidebarItem {
    return {
        key: type,
        title: t(`sidebar.${type}Rooms`),
        items: rooms.map(room => {
            return {
                key: `room-${room.id}`,
                title: ``,
                startContent: (
                    <div className="w-full flex justify-center md:justify-start items-center gap-2">
                        <Avatar 
                            src={`https://api.dicebear.com/6.x/initials/svg?seed=${encodeURIComponent(room.name)}`}
                            className="w-6 h-6 md:block hidden"
                            isBordered
                        />
                        <span className="truncate text-[10px] md:text-sm whitespace-pre-wrap break-words">{room.name}{room.is_private ? "" : " 🌐"}</span>
                    </div>
                ),
                className: "flex items-center cursor-pointer",
            };
        }),
    };
}

export function transformRoomData(roomData: { created: Room[]; joined: Room[] } | null, t: (key: string) => string): SidebarItem[] {
    const sidebarItems = getDefaultSidebarItems(t);

    if (roomData) {
        if (roomData.created && roomData.created.length > 0) {
            sidebarItems.push(createRoomItems(roomData.created as Room[], "created", t));
        }
        
        if (roomData.joined && roomData.joined.length > 0) {
            sidebarItems.push(createRoomItems(roomData.joined as Room[], "joined", t));
        }
    }

    return sidebarItems;
}

interface ConnectButtonProps {
    isCompact: boolean;
}

function ConnectButton({ isCompact }: ConnectButtonProps) {
    const { login } = usePrivy();
    const { t } = useTranslation();

    const handleConnect = async () => {
        login();
    }

    if (isCompact) {
        return (
            <Tooltip content={t('common.signIn')} placement="right">
                <button
                    className="flex items-center justify-center w-10 h-10 text-default-500 hover:text-foreground"
                    onClick={handleConnect}
                    aria-label={t('common.signIn')}
                >
                    <Icon
                        icon="line-md:login"
                        width={28}
                        aria-hidden="true"
                    />
                </button>
            </Tooltip>
        );
    }
    return (
        <Button size="md" onClick={handleConnect} className="mx-auto">
            {t('common.signIn')}
        </Button>
    );
}

export function AppContent() {
    const { ready, authenticated, user, login, logout, createWallet, exportWallet } = usePrivy();
    const { wallets } = useWallets();
    const location = useLocation();
    const navigate = useNavigate();
    const { videoId: urlVideoId } = useParams<{ videoId?: string }>();
    const { t } = useTranslation();

    const isCompact = useMediaQuery("(max-width: 768px)");
    const [hasUserHandleLoginSuccess, setUserHandleLoginSuccess] = useState(false);
    const [videos, setVideos] = useState<Video[]>([]);

    const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);

    const [contentLoaded, setContentLoaded] = useState(false);
    const [roomData, setRoomData] = useState<{
        created: Room[];
        joined: Room[];
    } | null>(null);

    const [userLoginJsonData, setUserLoginJsonData] = useState(() => {
        const savedData = localStorage.getItem('userLoginData');
        return savedData ? JSON.parse(savedData) : {
            userid: '-',
            jwt_token: '',
            valid: true,
            addr: '',
            avatar: '',
            nickname: '',
            gender: '',
            signature: '',
        };
    });

    const [embeddedWalletAddress, setEmbeddedWalletAddress] = useState('');

    const [showRechargeModal, setShowRechargeModal] = useState(false);

    const [walletInfo, setWalletInfo] = useState<{
        status: 'loading' | 'disconnected' | 'connected';
        balance: string;
        isInitialized: boolean;
    }>({
        status: 'loading',
        balance: '',
        isInitialized: false
    });

    const [copiedAddress, setCopiedAddress] = useState(false);
    const [isBalanceRefreshing, setIsBalanceRefreshing] = useState(false);

    const [cachedVideos, setCachedVideos] = useState<{[key: string]: Video[]}>({});

    const prevAuthenticatedRef = useRef(authenticated);

    const [isExportingWallet, setIsExportingWallet] = useState(false);

    const [isPrivateRoom, setIsPrivateRoom] = useState(false);

    useEffect(() => {
        const fetchWalletInfo = async () => {
            if (!ready) return;

            if (!authenticated) {
                setWalletInfo({ status: 'disconnected', balance: '', isInitialized: true });
                return;
            }

            const embeddedWallet = wallets.find(wallet => wallet.walletClientType === 'privy');
            if (!embeddedWallet) {
                setWalletInfo({ status: 'loading', balance: '', isInitialized: false });
                return;
            }

            setEmbeddedWalletAddress(embeddedWallet.address);

            try {
                const provider = new ethers.providers.JsonRpcProvider(polygon.rpcUrls.default.http[0]);
                const balance = await provider.getBalance(embeddedWallet.address);
                const formattedBalance = parseFloat(ethers.utils.formatEther(balance)).toFixed(3);
                setWalletInfo({ 
                    status: 'connected', 
                    balance: formattedBalance,
                    isInitialized: true 
                });
            } catch (error) {
                console.error('Error fetching wallet balance:', error);
                setWalletInfo({ 
                    status: 'connected', 
                    balance: 'Error',
                    isInitialized: true 
                });
            }
        };

        fetchWalletInfo();
    }, [ready, authenticated, wallets]);

    const getKeyFromPath = useCallback((path: string) => {
        if (path === '/') return 'newest';
        if (path === '/hot7d') return 'hot7d';
        if (path === '/home') return 'home';
        if (path.startsWith('/video/')) return 'newest';
        if (path === '/icebreakers') return 'icebreakers';
        if (path === '/creation' || path.startsWith('/creation/nftid/')) return 'creation';
        if (path.startsWith('/room/')) return `room-${path.split('/').pop()}`;
        if (path === '/rooms') return 'rooms';
        return 'newest';
    }, []);

    const [selectedSidebarKeys, setSelectedSidebarKeys] = useState<Set<string>>(new Set(["newest"]));

    const [pageType, setPageType] = useState(() => {
        const path = location.pathname;
        if (path === '/') return 'newest';
        if (path === '/home') return 'home';
        if (path === '/icebreakers') return 'icebreakers';
        if (path === '/creation') return 'creation';
        if (path === '/hot7d') return 'hot7d';
        return 'newest';
    });
    const abortControllerRef = useRef<AbortController | null>(null);

    const [isLoading, setIsLoading] = useState(true);
    const loadingTimerRef = useRef<NodeJS.Timeout | null>(null);

    const [isContentLoading, setIsContentLoading] = useState(true);

    const [isCreateRoomModalOpen, setIsCreateRoomModalOpen] = useState(false);
    const [roomName, setRoomName] = useState('');
    const [roomDescription, setRoomDescription] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const [isCreatingRoom, setIsCreatingRoom] = useState(false);

    useEffect(() => {
        if (ready) {
            loadingTimerRef.current = setTimeout(() => {
                setIsLoading(false);
            }, 800);
        }

        return () => {
            if (loadingTimerRef.current) {
                clearTimeout(loadingTimerRef.current);
            }
        };
    }, [ready]);

    const fetchRoomData = async () => {
        try {
            const response = await api.getRoom(userLoginJsonData.userid);
            if (response.success) {
                setRoomData(response.data);
            } else {
                console.error("Failed to fetch room data");
            }
        } catch (error) {
            console.error("An error occurred while fetching room data:", error);
        }
    };

    const fetchContent = useCallback(async (videoListType: string, forceRefresh: boolean = false) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        setIsContentLoading(true);

        // 如果强制刷新或者没有缓存的视频数据，则重新获取数据
        if (forceRefresh || !cachedVideos[videoListType]) {
            try {
                let response;
                if (videoListType === "home") {
                    response = await api.getFeed(userLoginJsonData.userid || 'guest', 0, abortController.signal);
                } else {
                    response = await api.getVideoList(
                        videoListType === "newest" || videoListType === "hot7d" ? "world" : videoListType,
                        userLoginJsonData.userid || '-',
                        userLoginJsonData.userid || '-',
                        0,
                        videoListType,
                        abortController.signal
                    );
                }

                if (abortController.signal.aborted) {
                    return;
                }

                if (response.success && Array.isArray(response.data)) {
                    setVideos(Array.isArray(response.data) ? response.data : [response.data]);
                    setCachedVideos(prev => ({
                        ...prev, 
                        [videoListType]: Array.isArray(response.data) ? response.data : [response.data]
                    }));
                } else {
                    console.error(`Failed to fetch ${videoListType} video data:`, response);
                }
            } catch (error) {
                console.error(`An error occurred while fetching ${videoListType} video data:`, error);
            } finally {
                if (abortControllerRef.current === abortController) {
                    setIsContentLoading(false);
                    abortControllerRef.current = null;
                }
            }
        } else {
            // 使用存的数据
            setVideos(cachedVideos[videoListType]);
            setIsContentLoading(false);
        }
    }, [userLoginJsonData.userid, cachedVideos]);

    const handleDisconnect = async (forceReload = false) => {
        await logout();
        if (hasUserHandleLoginSuccess || forceReload) {
            localStorage.clear();
            sessionStorage.clear();
            document.cookie.split(";").forEach((c) => {
                document.cookie = c
                    .replace(/^ +/, "")
                    .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            });
            window.location.reload();
        }
    };

    const handleItemClick = useCallback((key: string) => {
        let newPath = "/";

        if (key === "icebreakers") {
            newPath = '/icebreakers';
        } else if (key === "creation") {
            newPath = '/creation';
        } else if (key === "newest") {
            newPath = "/";
        } else if (key === "hot7d") {
            newPath = "/hot7d";
        } else if (key === "home") {
            newPath = "/home";
        } else if (key === "rooms") {
            newPath = "/rooms";
        } else if (key.startsWith('room-')) {
            const roomId = key.split('-')[1];
            newPath = `/room/${roomId}`;
        }

        navigate(newPath);
        setSelectedSidebarKeys(new Set([key]));
        setPageType(key);

        if (['newest', 'home', 'hot7d'].includes(key)) {
            setVideos([]);
            fetchContent(key, true);
        }
    }, [navigate, fetchContent]);

    const sidebarItems = useMemo(() => {
        const items = transformRoomData(roomData, t);
        return items.map(section => ({
            ...section,
            items: section.items?.map(item => ({
                ...item,
                onClick: () => {
                    console.log('Sidebar item clicked:', item.key);
                    handleItemClick(item.key);
                },
                className: `${item.className || ''} cursor-pointer`,
            })) || []
        }));
    }, [roomData, handleItemClick, t]);

    const handleVideoClick = (videoId: string) => {
        setSelectedVideoId(videoId);
        // 使用 history.push 更新 URL，但不触发新的路由渲染
        window.history.pushState(null, '', `/video/${videoId}`);
    };

    const handleCloseVideoDetail = () => {
        setSelectedVideoId(null);

        // 检查路由历史
        if (location.key === 'default' && location.pathname.startsWith('/video/')) {
            // 如果是直接访问或刷新的视频页面，跳转到主页
            window.location.href = '/';
        } else {
            // 否则，执行正常的返回操作
            navigate(-1);
        }
    };

    const handleLogin = async (privyid: string | undefined, address: string | undefined) => {
        try {
            const res = await fetch(`${API_BASE_URL}/signin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ privyid, address }),
                credentials: 'include'
            });

            const data = await res.json();
            if (data.success) {
                setUserHandleLoginSuccess(true);
                setUserLoginJsonData(() => ({
                    userid: data.accountInfo.userid,
                    jwt_token: data.accountInfo.jwt_token,
                    valid: data.accountInfo.valid,
                    addr: data.accountInfo.addr,
                    avatar: data.accountInfo.avatar,
                    nickname: data.accountInfo.nickname,
                    gender: data.accountInfo.gender,
                    signature: data.accountInfo.signature
                }));
                localStorage.setItem('userLoginData', JSON.stringify(data.accountInfo));
            } else {
                throw new Error('SIWE verification failed');
            }
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    useEffect(() => {
        if (ready) {
            const path = location.pathname;
            let newKey = getKeyFromPath(path);
            
            if (path === '/') {
                newKey = 'newest';
            } else if (path === '/home') {
                newKey = 'home';
            } else if (path === '/hot7d') {
                newKey = 'hot7d';
            } else if (path === '/rooms') {
                newKey = 'rooms';
            }
          
            const newKeys = new Set<string>([newKey]);
            setSelectedSidebarKeys(newKeys);
    
            setPageType(newKey);

            if (path.startsWith('/video/')) {
                const videoIdFromPath = path.split('/').pop();
                if (videoIdFromPath) {
                    setSelectedVideoId(videoIdFromPath);
                }
            } else if (path === '/' || path === '/home' || path === '/hot7d') {
                fetchContent(newKey);
            } else {
                if (path === '/icebreakers' || path === '/creation' || 
                    path.startsWith('/creation/nftid/') || path === '/rooms') {
                    setIsContentLoading(false);
                } else if (path.startsWith('/room/')) {
                    const roomId = path.split('/')[2];
                    newKeys.clear();
                    newKeys.add(`room-${roomId}`);
                    setSelectedSidebarKeys(newKeys);
                    setIsContentLoading(false);
                }
            }
        }
    }, [ready, authenticated, userLoginJsonData.userid, location.pathname, getKeyFromPath, fetchContent]);

    useEffect(() => {
        if (userLoginJsonData.userid !== '-' && !contentLoaded) {
            setContentLoaded(true);
            fetchRoomData();
        }
    }, [userLoginJsonData.userid]);

    useEffect(() => {
        if (!ready) return;

        const handleUserAuthentication = async () => {
            if (authenticated && user) {
                let walletAddress = user.wallet?.address;

                // 如果用户没有钱包，创建一个嵌入式钱包
                if (!walletAddress) {
                    try {
                        console.log('Creating embedded wallet...');
                        await createWallet();
                        // 重新获取钱包信息
                        const embeddedWallet = wallets.find(w => w.walletClientType === 'privy');
                        walletAddress = embeddedWallet?.address;
                    } catch (error) {
                        console.error('Failed to create embedded wallet:', error);
                    }
                }

                if (walletAddress) {
                    console.log('Connected 🟢');
                    await handleLogin(user.id, walletAddress);
                } else {
                    console.log('Failed to get or create wallet 🟠');
                }
            } else if (!authenticated && prevAuthenticatedRef.current) {
                console.log('Privy logout detected');
                console.log('Disconnected 🔴');
                handleDisconnect(true);
            }
        };

        handleUserAuthentication();
        prevAuthenticatedRef.current = authenticated;

    }, [authenticated, user, ready, wallets, userLoginJsonData.userid, createWallet]);

    useEffect(() => {
        if (urlVideoId) {
            setSelectedVideoId(urlVideoId);
        } else if (location.pathname === '/') {
            setSelectedVideoId('');
        }
    }, [urlVideoId, location.pathname]);

    const handleCreateRoom = async () => {
        if (!roomName.trim()) {
            enqueueSnackbar(t('snackbar.room.nameRequired'), { variant: 'warning' });
            return;
        }

        if (!roomDescription.trim()) {
            enqueueSnackbar(t('snackbar.room.descriptionRequired'), { variant: 'warning' });
            return;
        }

        setIsCreatingRoom(true);
        try {
            const response = await api.setRoomCreate(roomName, roomDescription, isPrivateRoom ? 1 : 0);
            if (response.success) {
                enqueueSnackbar(t('snackbar.room.createSuccess'), { variant: 'success' });
                setIsCreateRoomModalOpen(false);
                setRoomName('');
                setRoomDescription('');
                setIsPrivateRoom(false);
                fetchRoomData();
            } else {
                enqueueSnackbar(response.msg || t('snackbar.room.createError'), { variant: 'error' });
            }
        } catch (error) {
            console.error('Error creating room:', error);
            enqueueSnackbar(t('snackbar.room.createError'), { variant: 'error' });
        } finally {
            setIsCreatingRoom(false);
        }
    };

    const handleAvatarClick = () => {
      if (userLoginJsonData.addr !== '') {
        setShowRechargeModal(true);
      } else {
        login();
      }
    };

    const refreshBalance = async () => {
      if (!embeddedWalletAddress) return;

      setIsBalanceRefreshing(true);
      try {
        const provider = new ethers.providers.JsonRpcProvider(polygon.rpcUrls.default.http[0]);
        const balance = await provider.getBalance(embeddedWalletAddress);
        const formattedBalance = parseFloat(ethers.utils.formatEther(balance)).toFixed(3);
        setWalletInfo(prev => ({ 
            ...prev, 
            balance: formattedBalance,
            isInitialized: true
        }));
      } catch (error) {
        console.error('Error refreshing wallet balance:', error);
      } finally {
        setIsBalanceRefreshing(false);
      }
    };

    const handleAddFundsClick = () => {
      setShowRechargeModal(true);
      refreshBalance();
    };

    const handleExportWallet = async () => {
      if (!ready || !authenticated) {
        enqueueSnackbar('Please authenticate first', { variant: 'warning' });
        return;
      }

      const hasEmbeddedWallet = user?.linkedAccounts.some(
        (account) => account.type === 'wallet' && account.walletClientType === 'privy'
      );

      if (!hasEmbeddedWallet) {
        enqueueSnackbar('No embedded wallet found to export', { variant: 'error' });
        return;
      }

      setIsExportingWallet(true);
      try {
        await exportWallet();
      } catch (error) {
        console.error('Error exporting wallet:', error);
        enqueueSnackbar('Failed to export wallet', { variant: 'error' });
      } finally {
        setIsExportingWallet(false);
      }
    };

    useEffect(() => {
        const handlePopState = () => {
            const videoIdFromPath = location.pathname.split('/').pop();
            if (location.pathname.startsWith('/video/') && videoIdFromPath) {
                setSelectedVideoId(videoIdFromPath);
            } else {
                setSelectedVideoId(null);
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        const path = location.pathname;
        let title = "V Watch Video";

        if (path.startsWith('/room/')) {
            title = "Room - V Watch Video";
        } else if (path === '/icebreakers') {
            title = "Icebreakers - V Watch Video";
        } else if (path === '/creation') {
            title = "Creation - V Watch Video";
        } else if (path === '/newest') {
            title = "Newest - V Watch Video";
        } else if (path === '/hot7d') {
            title = "Hot - V Watch Video";
        } else if (path === '/home') {
            title = "Home - V Watch Video";
        }

        document.title = title;
    }, [location.pathname]);

    useEffect(() => {
        // 禁用整体页面的滚动
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        document.body.style.height = '100%';

        return () => {
            // 组件卸载时恢复默认设置
            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.width = '';
            document.body.style.height = '';
        };
    }, []);

    // 修改这个函数来包含 'hot7d'
    const isPublicPage = useCallback((pageType: string) => {
        return ['newest', 'hot7d'].includes(pageType);
    }, []);

    return (
        <div className="flex h-dvh w-full overflow-hidden">
            {!userLoginJsonData.valid && (
                <div className="fixed inset-0 z-50 flex flex-col">
                    <div className="absolute inset-0 bg-black opacity-75"></div>
                    <div className="relative z-10 bg-red-500 text-white p-4 flex justify-between items-center">
                        <span>You are currently unable to log in due to access restrictions.</span>
                        <Button color="default" onClick={() => handleDisconnect(true)} aria-label="Logout and reload">
                            Logout
                        </Button>
                    </div>
                </div>
            )}
            <div className={cn(
                "relative flex h-full flex-col !border-r-small border-divider p-6 transition-width bg-gradient-to-b from-default-100 via-danger-100 to-secondary-100",
                {
                    "w-16 items-center px-2 py-6": isCompact,
                    "w-72": !isCompact,
                },
            )}>
                <div className={cn(
                    "flex items-center gap-3 px-3",
                    {
                        "justify-center gap-0": isCompact,
                    },
                )}>
                    <div className="flex h-8 w-8 items-center justify-center rounded-full">
                        <VIcon className="text-foreground" aria-label="V.Watch logo" />
                    </div>
                    <span
                        className={cn("text-small font-bold uppercase text-foreground", {
                            "w-0 opacity-0": isCompact,
                        })}
                    >
                        V.Watch
                    </span>
                </div>
                <Spacer y={8}/>
                <div className="flex items-center gap-3 px-3">
                    <Avatar
                        isBordered
                        className="flex-none cursor-pointer"
                        size="sm"
                        src={`https://robohash.org/${userLoginJsonData.userid}?size=300x300&set=set5`}
                        onClick={handleAvatarClick}
                    />
                    <div className={cn("flex max-w-full flex-col", {hidden: isCompact})}>
                        <p className="truncate text-small font-medium text-foreground">
                            {userLoginJsonData.nickname === '' ? t('common.anonymous') : userLoginJsonData.nickname}
                        </p>
                        <div className="flex items-center">
                            {userLoginJsonData.addr !== '' ? (
                                walletInfo.status === 'loading' ? (
                                    <p className="truncate text-tiny text-default-500 mr-1">Loading...</p>
                                ) : (
                                    <>
                                        <p className="truncate text-tiny text-default-500 mr-1">
                                            {walletInfo.balance} POL
                                        </p>
                                        {walletInfo.balance !== 'Error' && (
                                            <a 
                                                href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleAddFundsClick();
                                                }}
                                                className="text-tiny text-blue-500 hover:text-blue-700 ml-1"
                                                title={t('common.addFunds')}
                                            >
                                                {t('common.addFunds')}
                                            </a>
                                        )}
                                    </>
                                )
                            ) : (
                                <p className="truncate text-tiny text-default-500">
                                    {t('common.notConnected')}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <ScrollShadow className="-mr-6 h-full max-h-full py-6 pr-6">
                    <Sidebar
                        userid={userLoginJsonData.userid}
                        defaultSelectedKey="home"
                        selectedKeys={selectedSidebarKeys}
                        isCompact={isCompact}
                        items={sidebarItems}
                        onSelect={handleItemClick}
                        iconClassName="text-default-600 group-data-[selected=true]:text-foreground"
                        itemClasses={{
                            base: "data-[selected=true]:bg-default-400/20 data-[hover=true]:bg-default-400/10 cursor-pointer",
                            title: "text-default-600 group-data-[selected=true]:text-foreground",
                        }}
                        sectionClasses={{
                            heading: "text-default-600 font-medium",
                        }}
                        variant="flat"
                        aria-label="Main navigation"
                    />
                </ScrollShadow>
                <Spacer y={2}/>
                <div className={cn("mt-auto flex flex-col gap-2", {
                    "items-center": isCompact,
                })}>
                    <LanguageSwitcher />
                </div>
                <div className={cn("mt-auto flex flex-col gap-2", {
                    "items-center": isCompact,
                })}>
                    <div className={cn("w-full")}>
                        {!ready ? (
                            <div className="flex justify-center">
                                <Icon
                                    className="text-default-600"
                                    icon="eos-icons:three-dots-loading"
                                    width={36}
                                    aria-label="Loading"
                                />
                            </div>
                        ) : userLoginJsonData.addr === '' ? (
                            <div className="flex justify-center w-full">
                                <ConnectButton isCompact={isCompact} />
                            </div>
                        ) : (
                            <Tooltip content={t('common.signOut')} isDisabled={!isCompact} placement="right">
                                <button
                                    onClick={() => handleDisconnect(true)}
                                    className={cn(
                                        "w-full flex items-center justify-start px-3 py-2 text-default-500 hover:text-orange-700 data-[hover=true]:bg-default-400/10",
                                        {
                                            "justify-center": isCompact,
                                        },
                                    )}
                                    aria-label={isCompact ? t('common.signOut') : undefined}
                                >
                                    <Icon
                                        className="text-default-600"
                                        icon="bx:exit"
                                        width={28}
                                        aria-hidden="true"
                                    />
                                    {!isCompact && <span className="ml-3">{t('common.signOut')}</span>}
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex-1 overflow-hidden">
                {(authenticated || isPublicPage(pageType)) ? (
                    <div className="w-full h-full flex flex-col p-2 sm:p-4 overflow-hidden">
                        <main className="flex-1 overflow-auto overscroll-contain scrollbar-hide">
                            {isContentLoading ? (
                                <div className="w-full h-full flex items-center justify-center">
                                    <SpinnerV aria-label="Loading content" />
                                </div>
                            ) : (
                                <div className="p-0 sm:p-4 h-full">
                                    {pageType === 'rooms' ? (
                                        <RoomList onCreateRoom={() => setIsCreateRoomModalOpen(true)} />
                                    ) : pageType === 'icebreakers' ? (
                                        <IcebreakersContent />
                                    ) : pageType === 'creation' ? (
                                        <Creation />
                                    ) : videos.length > 0 ? (
                                        <VideoList videos={videos} onVideoClick={handleVideoClick} />
                                    ) : (
                                        <div className="flex items-center justify-center h-full">
                                            <div className="text-center max-w-sm">
                                                <Icon 
                                                    icon="mdi:video-off-outline"
                                                    className="text-gray-400 mb-4 mx-auto" 
                                                    width={64}
                                                    height={64} 
                                                    aria-hidden="true"
                                                />
                                                <h2 className="text-xl font-medium mb-2">
                                                    {t('videos.noVideosTitle')}
                                                </h2>
                                                <p className="text-gray-500 mb-2">
                                                    {t('videos.noVideosDesc')}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </main>
                    </div>
                ) : !authenticated && isLoading ? (
                    <div className="flex h-full flex-col items-center justify-center">
                        <SpinnerV aria-label="Loading" />
                    </div>
                ) : (
                    <div className="flex h-full flex-col items-center justify-center p-4 text-center">
                        <Icon 
                            icon="mdi:login" 
                            className="text-gray-400 mb-4" 
                            width={64} 
                            height={64} 
                            aria-hidden="true"
                        />
                        <h2 className="text-2xl font-semibold mb-2">
                            {t('videos.welcomeTitle')}
                        </h2>
                        <p className="text-gray-500 mb-4">
                            {t('videos.welcomeDesc')}
                        </p>
                        <ConnectButton isCompact={false} />
                    </div>
                )}
            </div>
            <Modal 
                isOpen={isCreateRoomModalOpen} 
                onClose={() => setIsCreateRoomModalOpen(false)}
                placement={isCompact ? "top" : "center"}
                classNames={{
                    base: "bg-gradient-to-br from-white to-gray-100",
                    header: "border-b border-gray-200",
                    body: "py-6",
                    footer: "border-t border-gray-200"
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                <h2 className="text-2xl font-bold text-gray-800">
                                    {t('room.createNewRoom')}
                                </h2>
                                <p className="text-sm text-gray-500">
                                    {t('room.setupCommunity')}
                                </p>
                            </ModalHeader>
                            <ModalBody>
                                <Input
                                    label={t('room.roomName')}
                                    placeholder={t('room.roomNamePlaceholder')}
                                    value={roomName}
                                    onChange={(e) => setRoomName(e.target.value)}
                                    required
                                    className="mb-4"
                                />
                                <Textarea
                                    label={t('room.description')}
                                    placeholder={t('room.descriptionPlaceholder')}
                                    value={roomDescription}
                                    onChange={(e) => setRoomDescription(e.target.value)}
                                    required
                                    className="mb-4"
                                />
                                <div className="flex items-center gap-2">
                                    <Switch
                                        isSelected={isPrivateRoom}
                                        onValueChange={setIsPrivateRoom}
                                        size="sm"
                                    />
                                    <span className="text-sm text-gray-600">
                                        {t('room.makePrivate')}
                                    </span>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose} disabled={isCreatingRoom}>
                                    {t('common.cancel')}
                                </Button>
                                <Button 
                                    color="primary" 
                                    onPress={handleCreateRoom}
                                    disabled={isCreatingRoom}
                                >
                                    {isCreatingRoom ? (
                                        <>
                                            <Icon icon="eos-icons:loading" className="mr-2" />
                                            {t('common.creating')}
                                        </>
                                    ) : (
                                        t('common.create')
                                    )}
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal 
                isOpen={showRechargeModal} 
                onClose={() => {
                    setShowRechargeModal(false);
                    refreshBalance(); // 再次刷新余额
                }}
                size="lg"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">
                                {t('wallet.addFundsTitle')}
                            </ModalHeader>
                            <ModalBody>
                                <Card>
                                    <CardBody>
                                        <div className="flex justify-between items-center mb-4">
                                            <h3 className="text-lg font-semibold">
                                                {t('wallet.currentBalance')}
                                            </h3>
                                            {isBalanceRefreshing ? (
                                              <div className="flex items-center justify-center">
                                                <Icon icon="svg-spinners:3-dots-fade" width="24" height="24" />
                                              </div>
                                            ) : (
                                              <p className="text-2xl font-bold">{walletInfo.balance} POL</p>
                                            )}
                                        </div>
                                        <Divider />
                                        <div className="mt-4">
                                            <Badge color="warning" className="mb-2">
                                                {t('wallet.importantNotice')}
                                            </Badge>
                                            <p className="text-sm text-gray-600 mb-2">
                                                {t('wallet.scanQR')}
                                            </p>
                                            <div className="flex justify-center my-4">
                                                <QRCodeSVG value={embeddedWalletAddress} size={200} />
                                            </div>
                                            <Input
                                                readOnly
                                                label={t('wallet.walletAddress')}
                                                value={embeddedWalletAddress}
                                                endContent={
                                                    <Button 
                                                        size="sm"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(embeddedWalletAddress);
                                                            setCopiedAddress(true);
                                                            setTimeout(() => setCopiedAddress(false), 2000);
                                                        }}
                                                    >
                                                        {copiedAddress ? t('common.copied') : t('common.copy')}
                                                    </Button>
                                                }
                                            />
                                        </div>
                                    </CardBody>
                                    <CardFooter className="flex justify-between items-center">
                                        <Button 
                                            color="warning" 
                                            size="sm"
                                            onClick={handleExportWallet}
                                            disabled={isExportingWallet || !ready || !authenticated || !user?.linkedAccounts.some(
                                              (account) => account.type === 'wallet' && account.walletClientType === 'privy'
                                            )}
                                        >
                                            {isExportingWallet ? t('common.exporting') : t('common.export')}
                                        </Button>
                                        <Spacer x={1} />
                                        <Button color="primary" onPress={onClose}>
                                            {t('common.close')}
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
            {selectedVideoId && (
                <VideoDetailOverlay
                    videoId={selectedVideoId}
                    userId={userLoginJsonData.userid}
                    onClose={handleCloseVideoDetail}
                />
            )}
        </div>
    );
}

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<AppContent/>}>
                <Route index element={<AppContent/>}/>
                <Route path="home" element={<AppContent/>}/>
                <Route path="video/:videoId" element={<AppContent/>}/>
                <Route path="icebreakers" element={<AppContent/>}/>
                <Route path="creation" element={<Creation/>}/>
                <Route path="creation/nftid/:nftid" element={<Creation/>}/>
                <Route path="hot7d" element={<AppContent/>}/>
                <Route path="rooms" element={<AppContent/>}/>
            </Route>
            <Route path="room/:id" element={<RoomDetail/>}/>
            <Route path="user/:userid" element={<UserProfile/>}/>
        </Routes>
    );
}

export default function App() {
    return (
        <PrivyProvider
            appId="clnaj2zz2095tmq0f5gsbzb2t"
            config={{
                loginMethods: ['email', 'wallet', 'google'],
                appearance: {
                    theme: 'light',
                    accentColor: '#676FFF',
                    logo: 'https://stlink-us.s3.amazonaws.com/files/v-watch-logo.png',
                },
                embeddedWallets: {
                    createOnLogin: 'all-users',
                },
                supportedChains: [mainnet, polygon],
            }}
        >
            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                    <SnackbarProvider 
                        maxSnack={3} 
                        autoHideDuration={3000} 
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <BrowserRouter>
                            <AppRoutes/>
                        </BrowserRouter>
                    </SnackbarProvider>
                </QueryClientProvider>
            </WagmiProvider>
        </PrivyProvider>
    );
}
