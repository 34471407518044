import { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Input, Button, Avatar, Pagination, Chip, Divider } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { api, Room } from "../services/api";
import { useTranslation } from 'react-i18next';

export default function RoomList({ onCreateRoom }: { onCreateRoom: () => void }) {
    const { t } = useTranslation();
    const [rooms, setRooms] = useState<Room[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isSearching, setIsSearching] = useState(false);
    const [currentSearchTerm, setCurrentSearchTerm] = useState("");
    const itemsPerPage = 12;

    // 搜索房间
    const searchRooms = useCallback(async (keyword: string, page: number) => {
        setIsLoading(true);
        try {
            const offset = (page - 1); // 修改为从0开始的offset
            const response = await api.getRoomSearch(keyword, offset);
            if (response.success) {
                const roomData = response.data;
                setRooms(roomData.rooms);
                setTotalPages(Math.ceil(roomData.total / itemsPerPage));
            }
        } catch (error) {
            console.error('Failed to search rooms:', error);
        } finally {
            setIsLoading(false);
        }
    }, [itemsPerPage]);

    // 获取所有房间
    const fetchRooms = useCallback(async (page: number) => {
        setIsLoading(true);
        try {
            const offset = (page - 1); // 修改为从0开始的offset
            const response = await api.getRoomAll(offset);
            if (response.success) {
                const roomData = response.data as { total: number; rooms: Room[] };
                setRooms(roomData.rooms);
                setTotalPages(Math.ceil(roomData.total / itemsPerPage));
            }
        } catch (error) {
            console.error('Failed to fetch rooms:', error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    // 处理搜索
    const handleSearch = useCallback(() => {
        const trimmedQuery = searchQuery.trim();
        if (trimmedQuery) {
            setIsSearching(true);
            setCurrentPage(1);
            setCurrentSearchTerm(trimmedQuery);
            searchRooms(trimmedQuery, 1);
        } else {
            setIsSearching(false);
            setCurrentPage(1);
            setCurrentSearchTerm("");
            fetchRooms(1);
        }
    }, [searchQuery, searchRooms, fetchRooms]);

    // 处理搜索输入
    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    // 处理按键事件
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
            handleSearch();
        }
    };

    // 处理页面变化
    useEffect(() => {
        if (isSearching) {
            searchRooms(currentSearchTerm, currentPage);
        } else {
            fetchRooms(currentPage);
        }
    }, [currentPage, isSearching, searchRooms, fetchRooms, currentSearchTerm]);

    const handleRoomClick = (roomId: string) => {
        // 在新标签页打开
        window.open(`/room/${roomId}`, '_blank');
    };

    return (
        <div className="w-full max-w-7xl mx-auto p-4 space-y-8">
            {/* 标题和创建按钮 */}
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8">
                <div className="flex-1">
                    <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                        {t('roomList.title')}
                    </h1>
                    <p className="text-default-500 mt-1">
                        {t('roomList.subtitle')}
                    </p>
                </div>
                <Button 
                    color="primary" 
                    endContent={<Icon icon="material-symbols:add" />}
                    onPress={onCreateRoom}
                    size="lg"
                    className="w-full sm:w-auto bg-gradient-to-r from-primary to-secondary cursor-pointer touch-action-none"
                >
                    {t('roomList.createRoom')}
                </Button>
            </div>

            {/* 搜索栏 */}
            <div className="relative">
                <Input
                    placeholder={t('roomList.search.placeholder')}
                    value={searchQuery}
                    onChange={handleSearchInput}
                    onKeyDown={handleKeyDown}
                    size="lg"
                    radius="lg"
                    classNames={{
                        inputWrapper: "shadow-sm hover:shadow transition-shadow duration-200",
                    }}
                    endContent={
                        <>
                            {searchQuery && (
                                <div className="flex items-center">
                                    <Divider orientation="vertical" className="h-5 mx-2" />
                                    <Button 
                                        isIconOnly
                                        variant="light" 
                                        size="sm"
                                        onPress={() => {
                                            setSearchQuery('');
                                            setIsSearching(false);
                                            fetchRooms(1);
                                        }}
                                        className="cursor-pointer touch-action-none"
                                    >
                                        <Icon icon="material-symbols:close" className="text-default-400" />
                                    </Button>
                                </div>
                            )}
                            <Button
                                size="sm"
                                variant="flat"
                                color="secondary"
                                isLoading={isLoading}
                                onPress={handleSearch}
                                className="min-w-[80px] h-8 mx-1 cursor-pointer touch-action-none"
                                startContent={!isLoading && <Icon icon="material-symbols:search" />}
                            >
                                {t('roomList.search.button')}
                            </Button>
                        </>
                    }
                />
            </div>

            {/* 搜索结果状态提示 */}
            {isSearching && (
                <div className="flex items-center justify-between text-default-500 text-sm px-1">
                    <span>
                        {t('roomList.search.results', {
                            count: rooms.length,
                            term: currentSearchTerm
                        })}
                    </span>
                    <Button 
                        size="sm"
                        variant="light" 
                        color="secondary"
                        onPress={() => {
                            setSearchQuery('');
                            setIsSearching(false);
                            fetchRooms(1);
                        }}
                        className="cursor-pointer touch-action-none"
                    >
                        {t('roomList.search.clear')}
                    </Button>
                </div>
            )}

            {/* 房间列表内容 */}
            {isLoading ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {[...Array(6)].map((_, i) => (
                        <Card key={i} className="relative overflow-hidden bg-content1 min-h-[100px] p-0">
                            <CardBody className="overflow-visible p-4">
                                <div className="flex gap-4">
                                    <div className="w-12 h-12 rounded-full bg-default-200 animate-pulse shrink-0 ring-2 ring-default-200" />
                                    <div className="flex-1">
                                        <div className="h-6 w-3/4 bg-default-200 rounded animate-pulse mb-2" />
                                        <div className="h-4 w-full bg-default-200 rounded animate-pulse mb-2" />
                                        <div className="h-4 w-4/5 bg-default-200 rounded animate-pulse mb-4" />
                                    </div>
                                </div>
                            </CardBody>
                            <div className="absolute inset-0 bg-gradient-to-r from-default-200/5 via-transparent to-default-200/5 opacity-30" />
                        </Card>
                    ))}
                </div>
            ) : rooms.length === 0 ? (
                <div className="flex flex-col items-center justify-center py-12 text-default-500">
                    <Icon icon="material-symbols:search-off" className="text-5xl mb-4" />
                    <p className="text-xl">{t('roomList.noResults')}</p>
                    {searchQuery && (
                        <p className="mt-2">
                            {t('roomList.tryAgain')}{' '}
                            <Button 
                                color="primary" 
                                variant="light" 
                                onClick={() => {
                                    setSearchQuery('');
                                    setIsSearching(false);
                                    fetchRooms(1);
                                }}
                            >
                                {t('roomList.search.clear')}
                            </Button>
                        </p>
                    )}
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {rooms.map((room) => (
                            <Card 
                                key={room.id}
                                isPressable
                                onPress={() => handleRoomClick(room.id)}
                                className="group relative overflow-hidden bg-content1 transition-all duration-300 ease-in-out 
                                hover:scale-[1.01] hover:shadow-lg hover:-translate-y-0.5
                                hover:ring-2 hover:ring-primary/20 dark:hover:ring-primary/30"
                            >
                                <CardBody className="overflow-visible p-4">
                                    <div className="flex gap-4">
                                        <Avatar
                                            src={`https://api.dicebear.com/6.x/initials/svg?seed=${room.name}`}
                                            className="w-12 h-12 min-w-[48px] min-h-[48px] transition-transform duration-300 group-hover:scale-105"
                                            isBordered
                                        />
                                        <div className="flex-1">
                                            <h4 className="text-lg font-semibold line-clamp-1 group-hover:text-primary transition-colors">
                                                {room.name}
                                            </h4>
                                            <p className="text-small text-default-500 line-clamp-2">
                                                {room.description}
                                            </p>
                                            <div className="flex items-center gap-2 mt-4">
                                                <Chip size="sm" variant="flat" className="transition-colors duration-300">
                                                    <span className="flex items-center gap-1">
                                                        <Icon icon="mdi:user-group" className="text-sm" />
                                                        {t('roomList.stats.followers', { count: room.followers_count })}
                                                    </span>
                                                </Chip>
                                                <Chip size="sm" variant="flat" className="transition-colors duration-300">
                                                    <span className="flex items-center gap-1">
                                                        <Icon icon="mdi:video" className="text-sm" />
                                                        {t('roomList.stats.videos', { count: room.videos_count })}
                                                    </span>
                                                </Chip>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                                <div className="absolute inset-0 bg-gradient-to-r from-primary/5 via-transparent to-secondary/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                            </Card>
                        ))}
                    </div>

                    <div className="flex justify-center mt-8">
                        <Pagination
                            total={totalPages}
                            page={currentPage}
                            onChange={setCurrentPage}
                            showControls
                            className="gap-2"
                            classNames={{
                                cursor: "bg-gradient-to-r from-primary to-secondary text-white",
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
}