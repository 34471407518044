import React, { useEffect, useRef, useState } from 'react';
import { Howl } from 'howler';
import { Button, Progress } from "@nextui-org/react";
import { Icon } from "@iconify/react";

interface AudioPlayerProps {
  src: string;
  onError?: (error: any) => void;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src, onError }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const howlRef = useRef<Howl | null>(null);
  const progressInterval = useRef<number>();

  useEffect(() => {
    setIsLoading(true);
    
    if (howlRef.current) {
      howlRef.current.unload();
    }

    howlRef.current = new Howl({
      src: [src],
      html5: true,
      preload: true,
      format: ['mp3', 'wav'],
      autoplay: false,
      xhr: {
        method: 'GET',
        headers: {
          'Content-Type': 'audio/mpeg',
        },
      },
      onload: () => {
        setDuration(howlRef.current?.duration() || 0);
        setIsLoading(false);
      },
      onplay: () => {
        setIsPlaying(true);
        startProgressTracking();
      },
      onpause: () => {
        setIsPlaying(false);
        stopProgressTracking();
      },
      onstop: () => {
        setIsPlaying(false);
        stopProgressTracking();
      },
      onend: () => {
        setIsPlaying(false);
        stopProgressTracking();
        setProgress(100);
      },
      onloaderror: (error) => {
        setIsLoading(false);
        if (onError) onError(error);
      },
    });

    return () => {
      stopProgressTracking();
      if (howlRef.current) {
        howlRef.current.unload();
      }
    };
  }, [src, onError]);

  const startProgressTracking = () => {
    if (progressInterval.current) {
      clearInterval(progressInterval.current);
    }
    progressInterval.current = window.setInterval(() => {
      if (howlRef.current) {
        const seek = howlRef.current.seek() as number;
        const duration = howlRef.current.duration();
        setCurrentTime(seek);
        setProgress((seek / duration) * 100);
      }
    }, 100);
  };

  const stopProgressTracking = () => {
    if (progressInterval.current) {
      clearInterval(progressInterval.current);
    }
  };

  const togglePlay = () => {
    if (!howlRef.current) return;

    try {
      if (howlRef.current.state() === 'unloaded') {
        setIsLoading(true);
        howlRef.current.load();
        
        howlRef.current.once('load', () => {
          setIsLoading(false);
          howlRef.current?.play();
        });
        
        return;
      }

      if (isPlaying) {
        howlRef.current.pause();
      } else {
        if (howlRef.current.state() === 'loaded') {
          if (currentTime > 0) {
            howlRef.current.seek(currentTime);
          }
          howlRef.current.play();
        }
      }
    } catch (error) {
      console.error('Toggle play error:', error);
      setIsLoading(false);
      if (onError) onError(error);
    }
  };

  const handleSeek = (value: number) => {
    if (howlRef.current) {
      const newPosition = (value / 100) * duration;
      howlRef.current.seek(newPosition);
      setProgress(value);
      setCurrentTime(newPosition);

      if (!isPlaying) {
        howlRef.current.play();
        setIsPlaying(true);
      }
    }
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const seek10Seconds = (forward: boolean) => {
    if (howlRef.current) {
      const currentSeek = howlRef.current.seek() as number;
      const newPosition = forward ? currentSeek + 10 : currentSeek - 10;
      
      // Ensure we don't seek beyond bounds
      const clampedPosition = Math.max(0, Math.min(newPosition, duration));
      howlRef.current.seek(clampedPosition);
      setCurrentTime(clampedPosition);
      setProgress((clampedPosition / duration) * 100);
    }
  };

  return (
    <div className="w-full bg-gradient-to-r from-blue-50 to-purple-50 rounded-xl shadow-lg p-6">
      <div className="flex flex-col gap-4">
        {/* Progress bar section */}
        <div className="flex-grow">
          <div 
            className="relative w-full h-4 cursor-pointer"
            onClick={(e) => {
              const rect = e.currentTarget.getBoundingClientRect();
              const x = e.clientX - rect.left;
              const value = (x / rect.width) * 100;
              handleSeek(Math.max(0, Math.min(value, 100)));
            }}
          >
            <Progress
              aria-label="Audio progress"
              value={progress}
              className="w-full absolute top-0 left-0 [&>div]:!transition-none [&_div]:!transition-none [&_*]:!transition-none"
              classNames={{
                indicator: "transition-none",
                track: "transition-none",
                value: "transition-none",
              }}
              size="md"
              color="secondary"
              showValueLabel={false}
            />
          </div>
          <div className="flex justify-between mt-2 text-sm text-gray-600">
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>

        {/* Controls section */}
        <div className="flex items-center justify-center gap-4">
            <Button
              isIconOnly
              variant="light"
              onPress={() => seek10Seconds(false)}
              isDisabled={isLoading}
              className="w-10 h-10 text-gray-700 hover:bg-purple-100 transition-all"
            >
              <Icon icon="mdi:rewind-10" width={20} height={20} />
            </Button>

          <Button
            isIconOnly
            variant="solid"
            onPress={togglePlay}
            isDisabled={isLoading}
            className={`w-14 h-14 ${
              isPlaying 
                ? 'bg-purple-600 hover:bg-purple-700' 
                : 'bg-blue-600 hover:bg-blue-700'
            } shadow-lg transition-all`}
          >
            {isLoading ? (
              <Icon
                icon="eos-icons:loading"
                className="text-white animate-spin"
                width={28}
                height={28}
              />
            ) : (
              <Icon
                icon={isPlaying ? "mdi:pause" : "mdi:play"}
                className="text-white"
                width={28}
                height={28}
              />
            )}
          </Button>

          <Button
              isIconOnly
              variant="light"
              onPress={() => seek10Seconds(true)}
              isDisabled={isLoading}
              className="w-10 h-10 text-gray-700 hover:bg-purple-100 transition-all"
            >
              <Icon icon="mdi:fast-forward-10" width={20} height={20} />
            </Button>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;